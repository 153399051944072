import React,{useState} from 'react'
import CustomTable from '../../components/CustomTable/CustomTable'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import '../CampList/CampList.css'
import DownloadCSV from '../DownloadCSV';

const tableHeaders = [
  'S. No.', 'Name Of Mobilizer/Swalambi', 'Aadhar No.', 'Mobile No.', 'District','Total Candidates','Selected', 'Under Process','Rejected','Payment Done','Payment Due','Status','Action'
]
const tableKeys = ['sNo', 'nameOfMob','aadharNo','mobileNo','district','totalCandidates','selected','underProcess','rejected','paymentDone','paymentDue','status','action'];


export default function MobSwWise (){
  const [rowData, setRowData] = useState([]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/camps`, {
//         headers: {
//           "Authorization": `Bearer ${JSON.stringify({
//             token: localStorage.getItem("token")
//           })}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       setRowData(data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const headers = {
//     "Authorization": `Bearer ${JSON.stringify({
//       token: window.localStorage.getItem("token")
//     })}`,
//     "Content-Type": "application/json"
//   }

//    const handleStatusChange = async (index, value) => {
//     const updatedRowData = [...rowData];
//     updatedRowData[index].status = value;
//     setRowData(updatedRowData);
//     try{
//       const id = updatedRowData[index]._id
//       await axios.put(`${process.env.REACT_APP_API_URL}/camps/${id}`, updatedRowData[index], {headers});
//     } catch(error) {
//       console.error('Error updating data:', error);
//     }
//   };

  return (
    <div className='cl-main-container'>
      <h3 className='cl-heading' style={{marginTop:'25px'}}>Mobiliser/ Swalambi wise/ Self</h3>
      <DownloadCSV headers={tableHeaders} data={rowData} filename="mobSw.csv" />
       <CustomTable
        rows={rowData.map((candidate, index) => ({
          ...candidate,
          action: (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Action</InputLabel>
              <Select
                value={candidate.action}
                // onChange={(e) => handleStatusChange(index, e.target.value)}
                label="Action"
              >
                <MenuItem value="mobiliser">Block</MenuItem>
                <MenuItem value="swalambi">Active</MenuItem>
              </Select>
            </FormControl>
          ),
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  )
}