import React, { useState, useEffect } from 'react';
import BasicBars from '../../components/BasicBar/BasicBar';
import './MobiliserDashboard.css';
import {jwtDecode} from "jwt-decode";


export default function MobiliserDashboard() {
    const [name, setName] = useState("");
    const [boxNumbers, setBoxNumbers] = useState({
        totalRegistered: 0,
        totalMoved: 0,
        totalUnderReview: 0,
        totalSelected: 0,
        totalRejected: 0
    });
    const [xAxisDataDate, setXAxisDataDate] = useState([]);
    const [seriesDataDate, setSeriesDataDate] = useState([]);
    const [xAxisDataCamp, setXAxisDataCamp] = useState([]);
    const [seriesDataCamp, setSeriesDataCamp] = useState([]);

    useEffect(() => {
        const headers = {
            "Authorization": `Bearer ${JSON.stringify({
              token: localStorage.getItem("token")
            })}`,
            "Content-Type": "application/json"
          }
          // Fetch name
          const token = localStorage.getItem("token");

          if (token) {
              // Decode the token to extract the user ID
              const decodedToken = jwtDecode(token);
              const Name = decodedToken.name;
              setName(Name);
            }

        // Fetch box numbers
        fetch(`${process.env.REACT_APP_API_URL}/candidates/categorize/status
        `, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setBoxNumbers(data);
            })
            .catch(error => console.error('Error fetching box numbers:', error));

        // Fetch xAxisDataDate and seriesDataDate
        fetch(`${process.env.REACT_APP_API_URL}/candidates/divisionByMonth`, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                setXAxisDataDate(data.xAxisData|| []);
                setSeriesDataDate(data.seriesData|| []);
            })
            .catch(error => console.error('Error fetching date data:', error));

        // Fetch xAxisDataCamp and seriesDataCamp
        fetch(`${process.env.REACT_APP_API_URL}/candidates/divisionByCamp`, {headers})
            .then(response => {
                if (response.status === 204) {
                    console.log('No camps found');
                    return;
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setXAxisDataCamp(data.xAxisData|| []);
                setSeriesDataCamp(data.seriesData|| []);
            })
            .catch(error => console.error('Error fetching camp data:', error));
    }, []);

    return (
        <div className="m-main-content">
            <h3 className="m-title">{`Hello ${name} (Mobiliser)`}</h3>
            <div className='box-wrapper'>
                <div className="box-container">
                    <div className="box">
                        <div className="box-title">Total Registered</div>
                        <div className="box-number">{boxNumbers.totalRegistered}</div>
                    </div>
                    <div className="box">
                        <div className="box-title">Total Moved</div>
                        <div className="box-number">{boxNumbers.totalMoved}</div>
                    </div>
                    <div className="box">
                        <div className="box-title">Total Under Review</div>
                        <div className="box-number">{boxNumbers.totalUnderReview}</div>
                    </div>
                    <div className="box">
                        <div className="box-title">Total Selected</div>
                        <div className="box-number">{boxNumbers.totalSelected}</div>
                    </div>
                    <div className="box">
                        <div className="box-title">Total Rejected</div>
                        <div className="box-number">{boxNumbers.totalRejected}</div>
                    </div>
                </div>
                <div className='bars-container'>
                <BasicBars xAxisData={xAxisDataDate} seriesData={seriesDataDate} xText="Month" yText="No. Of Candidate Added" />
                <BasicBars xAxisData={xAxisDataCamp} seriesData={seriesDataCamp} xText="Camp Name" yText="No. Of Candidate Added" />
                </div>
            </div>
        </div>
    );
}