import React,{useEffect,useState} from 'react';
import '../LederDashboard/LeaderDashboard.css';
import {jwtDecode} from "jwt-decode";
import CenterwiseTable from '../HeadMultiheaderTable/CenterwiseTable';
import JobRolewiseTable from '../HeadMultiheaderTable/JobRolewiseTable';
import YearwiseTable from '../HeadMultiheaderTable/YearWiseTable';
import { useCandidateBoxNumber ,useMobiliserStatus} from '../../queries/DashboardQueries';

export default function HeadDashboard() {
    const [name, setName] = useState("");
    const [boxNumbers, setBoxNumbers] = useState({
        totalRegistered: 0,
        totalMoved: 0,
        totalUnderReview: 0,
        totalSelected: 0,
        totalRejected: 0,
        totalEnrolled:0,
        totalPaymentDone:0,
        totalEnrollmentUnderProcess:0,
        totalPaymentUnderProcess:0
    });
    const [registrationThrough, setRegistrationThrough] = useState({
      totalMobiliser:0,
      totalSwalambi:0,
      totalSelf:0
    });
    const [mobiliserStatus, setMobiliserStatus] = useState({
      totalMobilisers:0,
      totalActive:0,
      totalInActive:0,
      totalBlocked:0
    });

    const { data: boxnumber, error,isLoading } = useCandidateBoxNumber();
    const { data: mobiliserstatus } = useMobiliserStatus();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            const Name = decodedToken.name;
            setName(Name);
        }
    }, []);

    useEffect(() => {
        if (boxnumber) {
            setBoxNumbers(boxnumber);
        }
    }, [boxnumber]);

    useEffect(() => {
        if (mobiliserstatus) {
            setMobiliserStatus(mobiliserstatus);
        }
    }, [mobiliserstatus]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading box numbers</div>;
    }

    return (
        <div className="main-content">
            <h3 className="l-title">{`Hello ${name} (Head)`}</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Registered</div>
                        <div className="l-box-number">{boxNumbers.totalRegistered}</div>
                    </div>
                    <div className="l-box">
                        <div className="box-title">Moved</div>
                        <div className="l-box-number">{boxNumbers.totalMoved}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Under Process</div>
                        <div className="l-box-number">{boxNumbers.totalUnderReview}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Selected</div>
                        <div className="l-box-number">{boxNumbers.totalSelected}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Rejected</div>
                        <div className="l-box-number">{boxNumbers.totalRejected}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Enrolled</div>
                        <div className="l-box-number">{boxNumbers.totalEnrolled}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Payment Done</div>
                        <div className="l-box-number">{boxNumbers.totalPaymentDone}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Enrollment Under Process</div>
                        <div className="l-box-number">{boxNumbers.totalEnrollmentUnderProcess}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Payment Under Process</div>
                        <div className="l-box-number">{boxNumbers.totalPaymentUnderProcess}</div>
                    </div>
                </div>
            </div>
            <h3 className='l-heading'>Registration Through</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Mobiliser</div>
                        <div className="l-box-number">{registrationThrough.totalMobiliser}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Swalambi</div>
                        <div className="l-box-number">{registrationThrough.totalSwalambi}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Self</div>
                        <div className="l-box-number">{registrationThrough.totalSelf}</div>
                    </div>
                </div>
            </div>
            <h3 className='l-heading'>Mobiliser Status</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Total Mobiliser</div>
                        <div className="l-box-number">{mobiliserStatus.totalMobilisers}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Active Mobiliser</div>
                        <div className="l-box-number">{mobiliserStatus.totalActive}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">In-Active Mobiliser</div>
                        <div className="l-box-number">{mobiliserStatus.totalInActive}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Blocked Mobiliser</div>
                        <div className="l-box-number">{mobiliserStatus.totalBlocked}</div>
                    </div>
                </div>
            </div>
            <h3 className='l-heading'>Center Wise Details</h3>
            <CenterwiseTable/>
            <h3 className='l-heading'>Job Role Wise Details</h3>
            <JobRolewiseTable/>
            <h3 className='l-heading'>Year Wise Details</h3>
            <YearwiseTable/>
        </div>
    );
}