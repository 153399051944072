import React, { useState } from "react";
// import "../../components/css/main.css"
import "./SignUp.css"
import { useNavigate } from "react-router-dom";
import HandleSuccessToast from '../../helpers/HandleSuccessToast'
import HandleErrorToast from "../../helpers/HandleErrorToast";
import { TextField, Typography, Grid, Link, } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function SignUp() {
  const navigate = useNavigate()
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [post, setPost] = useState("");
  const [zone, setzone] = useState("");
  const [district, setDistrict] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [block,setBlock] = useState("");

  const handleSubmit = (e) => {    
      e.preventDefault();
      fetch(`${process.env.REACT_APP_API_URL}/employees`, {        
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname,
          email,
          lname,
          password,
          userType,
          post,zone, district, panchayat,block,
        }),
      })
        .then((res) => {
          if (res.ok) {                  
            HandleSuccessToast("Registered successfully");
            navigate('/login-user');
          } else {
            HandleErrorToast('Something went wrong');
          }
        })
    }
  
  return (
    <div className="signup-container">
        <form onSubmit={handleSubmit} className="signup-box">
        <Grid align="center">
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
        </Grid>
        <Typography component="h1" variant="h5" align="center" sx={{mb:2, marginTop:'-15px'}}>
            Sign up
          </Typography>
          <Grid item xs={12}>
            <TextField
              select
              sx={{width:'80%', height:""}}
              label="Select User Type"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="">Select User Type</option>
              <option value="mobiliser">Mobiliser</option>
              <option value="leader">Mobiliser Leader</option>
              <option value="support">Mobilisation Support</option>
              <option value="head">Mobilisation Head</option>
            </TextField>
          </Grid>
          
          {userType === 'mobiliser' && (
            <Grid item xs={12}>
             <TextField
              sx={{width:'80%', height:""}}
               label="Zone"
               variant="outlined"
               name="zone"
               onChange={(e) => setzone(e.target.value)}
             />
             <TextField
              sx={{width:'80%', height:"",mt:3}}
               label="District"
               variant="outlined"
               name="district"
               onChange={(e) => setDistrict(e.target.value)}
             />
              <TextField
              sx={{width:'80%', height:"",mt:3}}
               label="Panchayat"
                variant="outlined"
                name="panchayat"
                onChange={(e) => setPanchayat(e.target.value)}
              />
               <TextField
                sx={{width:'80%', height:"",mt:3}}
                  label="Block"
                  variant="outlined"
                  name="block"
                  onChange={(e) => setBlock(e.target.value)}
              />
           </Grid>
          )}
          {userType === 'leader' && (
                <Grid item xs={12}>
                  <TextField
                    select
                    sx={{width:'80%', height:""}}
                    label="Select Post"
                    value={post}
                    onChange={(e) => setPost(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Post</option>
                    <option value="ZSM">ZSM</option>
                    <option value="DSM">DSM</option>
                    <option value="PSM">PSM</option>
                    <option value="BSM">BSM</option>
                  </TextField>
                </Grid>
              )}
           {(post === 'ZSM' || post === 'DSM' || post === 'PSM' || post === 'BSM') && (
                <Grid item xs={12}>
                  <TextField
                   sx={{width:'80%', height:""}}
                    label="Zone"
                    variant="outlined"
                    name="zone"
                    onChange={(e) => setzone(e.target.value)}
                  />
                </Grid>
              )}
              {(post === 'DSM' || post === 'PSM' || post === 'BSM') && (
                <Grid item xs={12}>
                  <TextField
                     sx={{width:'80%', height:""}}
                    label="District"
                    variant="outlined"
                    name="district"
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </Grid>
              )}
              {(post === 'PSM' || post === 'BSM') && (
                <Grid item xs={12}>
                  <TextField
                     sx={{width:'80%', height:""}}
                    label="Panchayat"
                    variant="outlined"
                    name="panchayat"
                    onChange={(e) => setPanchayat(e.target.value)}
                  />
                </Grid>
              )}
              {post === 'BSM' && (
                <Grid item xs={12}>
                  <TextField
                     sx={{width:'80%', height:""}}
                    label="Block"
                    variant="outlined"
                    name="block"
                    onChange={(e) => setBlock(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  sx={{width:'80%', height:""}}
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(e) => setFname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  sx={{width:'80%', height:""}}
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(e) => setLname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{width:'80%', height:""}}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{width:'80%', height:""}}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

          <div >
            <button type="submit" className="signup-btn">
              Sign Up
            </button>
          </div>

          <Grid container justifyContent="flex-end" sx={{pr:3}}>
              <Grid item>
                <Link href="/login-user" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>

        </form>
      </div>
  );
}
