import React,{useState,useEffect} from 'react'
import CustomTable from '../../components/CustomTable/CustomTable'
import '../CampList/CampList.css'
import { useCandidateStatusList } from '../../queries/ManageCandidateQueries';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = [
  'S. No.', 'Name', "Father's Name", 'Aadhar No.', 'Mobile No.', 'District','Registration Date','Name Of Mobiliser/Swalambi', 'Status','Remark'
]
const tableKeys = ['sNo', 'name', 'fatherName', 'aadharNo','mobileNo','district','registrationDate','nameOfMob','status','remark'];


export default function Rejected(){
  const [rowData, setRowData] = useState([]);

  const { data: candidates, error,isLoading } = useCandidateStatusList('rejected');

  useEffect(() => {
    if (candidates?.length) {
      setRowData(candidates.map((candidate, index) => ({
        ...candidate,
        sNo: index + 1,
        registrationDate: candidate.registrationDate.split('T')[0], 
        isEditing: false,
      })));
    }
  }, [candidates]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  return (
    <div className='cl-main-container'>
      <h3 className='cl-heading' style={{marginTop:'25px'}}>Rejected Candidates List</h3>
      <DownloadCSV headers={tableHeaders} data={rowData} filename="candidateRejected.csv" />
       <CustomTable
        rows={rowData}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  )
}