import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './AutoComplete.css'

export default function AutoComplete(props) {
  const { label, styles, value,options=[] ,handleAutocompleteChange, name} = props;
  const [internalValue, setInternalValue] = React.useState(value || null);

  const handleInputChange = (event, value) => {
    setInternalValue(value);
    handleAutocompleteChange(name, value);
  };

  React.useEffect(() => {
    setInternalValue(value || null);
  }, [value]);

  return (
    <Autocomplete
      value={internalValue}
      onChange={handleInputChange}
      className='autocomplete'
      disablePortal
      id="combo-box-demo"
      style={styles}
      options={options}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label || '')}
      sx={{ '& .MuiOutlinedInput-root':{padding:'0'}}}
      renderInput={(params) => 
      <TextField 
      {...params  }
      label={label}
      id="filled"
      size="small"
      InputLabelProps={{
        sx: { bgcolor: "#DBE2EF", borderRadius:'5px', fontSize:'15px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
      }}
      className='textField2'
      InputProps={{
        style:{borderColor:'#707C8B'}
      }}
      sx={{
        "& label.Mui-focused": {
          color: "#000",
          bgcolor: "#DBE2EF", 
          borderRadius:'5px', 
          fontSize:'15px', 
          lineHeight:'20.25px', 
          padding:'2px 8px 2px 8px',
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#707C8B",
          },
          "&:hover fieldset": {
            borderColor: "#707C8B",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#707C8B",
          },
        },
      }}
      focused
        {...params}   
         />}
    />
  );
}

