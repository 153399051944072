import React from 'react'
import contactImg from "../../images/Contact-Us.jpg"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './Contact.css'


function Contact() {
  return (
    <div className='contact-container'>
      <div className='img-container'>
      <img src={contactImg} alt='contact'></img>
      </div>
      <div className='card-container'>
      <div class="card">
        <div class="icon" style={{color: "blue"}}><LocationOnIcon /></div>
        <div class="content">
            <h2>Address Way</h2>
            <p style={{textAlign:'justify'}}>3rd Floor, Sri Mohan Building, Sita Compound 5, Near Over Bridge, Main Road, Ranchi - 834001 (Jharkhand)</p>
        </div>
    </div>

    <div class="card">
        <div class="icon" style={{color: "green"}}><PhoneIcon /></div>
        <div class="content">
            <h2>Contact Info</h2>
            <p>Mobile: 0651 - 2332878</p>
            <p>Mail: info@sgrsgroup.com</p>
        </div>
    </div>

    <div class="card">
        <div class="icon" style={{color: "orange"}}><ScheduleIcon /></div>
        <div class="content">
            <h2>Working Time</h2>
            <p style={{textAlign:'justify'}}>Monday - Saturday: 09:00 AM - 05:00 PM</p>
            <p>Sunday: Holiday</p>
                  
        </div>
    </div>

      </div>
      
      <div className='map-container'>
      <iframe loading="lazy" src="https://maps.google.com/maps?q=Sri%20Mohan%20Building%2C%20Sita%20Compound%205%2C%20Near%20Over%20Bridge%2C%20Main%20Road%2C%20Ranchi%20-%20834001%20&amp;t=m&amp;z=12&amp;output=embed&amp;iwloc=near" title="Sri Mohan Building, Sita Compound 5, Near Over Bridge, Main Road, Ranchi - 834001 " aria-label="Sri Mohan Building, Sita Compound 5, Near Over Bridge, Main Road, Ranchi - 834001 "></iframe>
      </div>
    </div>
  )
}

export default Contact
