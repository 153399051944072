import React from 'react';
import Slider from 'react-slick';
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'

import './Home.css';
import NoticeBoard from '../../components/NoticeBoard/NoticeBoard';

// ... import other images similarly
function SampleNextArrow(props) {
 
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#1cbbb4" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#1cbbb4" }}
        onClick={onClick}
      />
    );
  }
  

const Home = () => {
  const navigate = useNavigate()
  // ... settings remain the same
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };


  return (
    <div className="home">
      <NoticeBoard/>
      <div className="slider-container">
        <Slider {...settings}>
          <div><img src='https://sgrsgroup.com/wp-content/uploads/2023/04/lalganj-centre.png' alt="Slide 1" className="slider-image"/></div>
          <div><img src='https://sgrsgroup.com/wp-content/uploads/2023/04/26th-January.png' alt="Slide 2" className="slider-image"/></div>
          <div><img src='https://sgrsgroup.com/wp-content/uploads/2023/03/2-2.png' alt="Slide 3" className="slider-image"/></div>
          <div><img src='https://sgrsgroup.com/wp-content/uploads/2023/03/1-2.png' alt="Slide 4" className="slider-image"/></div>
          <div><img src='https://sgrsgroup.com/wp-content/uploads/2023/03/3.png' alt="Slide 5" className="slider-image"/></div>

        </Slider>
       
      </div>
      <h1 className='home-heading'>Welcome to SGRS</h1>
      <span className='divider'></span>
        <p className='tagline-content'>
        SGRS Academic Pvt Ltd a Private Limited company registered under Companies Act, 1956 (No.1 of 1956)
        with CIN: U80900JH2011PTC014594 is a premier skill training company. SGRS has conducting of skill
        trainings to large number of youths with active collaboration with Central Government, Government
        Organizations, Non-Governmental Organizations, Corporates and Businesses. SGRS is implementing
        various skill development activities on PAN India basis.
        </p>
        <Button className='learn-button' variant='contained' onClick={() => navigate('/about')}>Learn More</Button>
    </div>
  );
};

export default Home;
