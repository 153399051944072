import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import '../CampList/CampList.css';
import { usePaymentStatusList } from '../../queries/ManagePaymentQueries';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = ['S. No.', 'Name', "Father's Name", 'Aadhar No.', 'Mobile No.', 'District', 'Registration Date', 'Name of (Mobiliser/Swalambi)', 'Status', 'Remarks'];
const tableKeys = ['sNo', 'nameOfCandidate', 'fatherName', 'aadharNo', 'mobileNo', 'district', 'registrationDate', 'nameOfMs', 'status', 'remark'];

export default function UnderProcess() {
  const [rowData, setRowData] = useState([]);
  const { data: payments, error, isLoading } = usePaymentStatusList('partial');

  useEffect(() => {
    if (payments?.length) {
      setRowData(payments.map((payment, index) => ({
        ...payment,
        sNo: index + 1,
        registrationDate: payment.registrationDate ? payment.registrationDate.split('T')[0] : 'N/A',
        isEditing: false,
      })));
    }
  }, [payments]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  return (
    <div className='cl-main-container'>
      <h3 className='cl-heading' style={{ marginTop: '25px' }}>Payment Under Process List</h3>
      <DownloadCSV headers={tableHeaders} data={rowData} filename="paymentUnderProcess.csv" />
      <CustomTable
        rows={rowData}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  )
}
