import React from 'react';
import { Box, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CustomTextField from '../TextField/TextField';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import HandleErrorToast from '../../helpers/HandleErrorToast';
import "../CreateCamp/CreateCamp.css";
import { useAddMobiliser } from '../../queries/ManageMobiliserQueries';
import AutoComplete from '../AutoComplete/AutoComplete';
import { postOptions, stateOptions } from '../dropdownOptions';

export default function AddMobiliser(props) {
  const { handleSubmit, control, reset, setValue } = useForm();
  const { mutate: addMobiliser, isLoading } = useAddMobiliser();

  const onSubmit = async (formData) => {
    const dataToSend = {
      ...formData,
      aadharPhoto: formData.aadharPhoto || [],
      photo: formData.photo || [],
      panPhoto: formData.panPhoto || [],
      bankAccountPhoto: formData.bankAccountPhoto || [],
    };

    addMobiliser(dataToSend, {
      onSuccess: () => {
        HandleSuccessToast('Mobiliser added successfully');
        reset();
        props.onFormSubmitSuccess();
      },
      onError: async (error) => {
        let errorMessage = 'Something went wrong';
        if (error.response) {
          const errorText = await error.response.text();
          errorMessage = errorText || errorMessage;
        }
        HandleErrorToast(errorMessage);
      },
    });
  };

  const handleAutocompleteChange = (name, value) => {
    if (value) {
      setValue(name, value.label);
    } else {
      setValue(name, '');
    }
  };

  return (
    <form className="cc-main-container" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="cc-heading">Basic Details</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Name" {...field} />}
        />
        <Controller
          name="fatherName"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Father's Name" {...field} />}
        />
        <Controller
          name="emailId"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Email ID" {...field} />}
        />
        <Controller
          name="aadharNo"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField type="number" label="Aadhar No." {...field} />}
        />
        <Controller
          name="panNo"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Pan No." {...field} />}
        />
        <Controller
          name="mobileNo"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField type="number" label="Mobile No." {...field} />}
        />
        <Controller
          name="dob"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField type="date" label="Date Of Birth" {...field} />}
        />
        <Controller
          name="dateOfRegistration"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField type="date" label="Date Of Registration" {...field} />}
        />
        <Controller
          name="post"
          control={control}
          defaultValue=""
          render={({ field }) => <AutoComplete  styles={{width:'50%'}} options={postOptions} handleAutocompleteChange={handleAutocompleteChange} label="post" {...field} />}
        />
      </Box>
      <h3 className='cc-heading' style={{marginBottom:'20px'}}>Address</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
      <Controller
          name="address"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Address" multiline {...field} />}
        />
        <Controller
          name="district"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="District" {...field} />}
        />
        <Controller
          name="panchayat"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Panchayat" {...field} />}
        />
        <Controller
          name="wardNumber"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField type="number" label="Ward Number" {...field} />}
        />
        <Controller
          name="block"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Block" {...field} />}
        />
        <Controller
          name="state"
          control={control}
          defaultValue=""
          render={({ field }) => <AutoComplete  styles={{width:'50%'}} options={stateOptions} handleAutocompleteChange={handleAutocompleteChange} label="State" {...field} />}
        />
      </Box>
      <h3 className='cc-heading' style={{marginBottom:'20px'}}>Upload Documents</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
        <Controller
          name="aadharPhoto"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CustomTextField
              label="Aadhar Image"
              type="file"
              onChange={(e) => field.onChange(Array.from(e.target.files))}
            />
          )}
        />
        <Controller
          name="photo"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CustomTextField
              label="Photograph"
              type="file"
              onChange={(e) => field.onChange(Array.from(e.target.files))}
            />
          )}
        />
        <Controller
          name="panPhoto"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CustomTextField
              label="Pan Card"
              type="file"
              onChange={(e) => field.onChange(Array.from(e.target.files))}
            />
          )}
        />
        <Controller
          name="bankAccountPhoto"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CustomTextField
              label="Bank Passbook"
              type="file"
              onChange={(e) => field.onChange(Array.from(e.target.files))}
            />
          )}
        />
      </Box>
      <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
        <Button variant="contained" className="submit-btn" type="submit" disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </Box>
    </form>
  );
}
