import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';

import './CustomTable.css'

const useStyles = makeStyles({
  tableContainer: {
    width: '90%',
    borderRadius: '4px',
    border:'1px solid #5B5B5B'
  },
  noDataCell:{
    height: '300px',
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#000',
    fontSize:'18px'
  },
  loadingCell: {
    height: '300px'
  }
});

export default function CustomTable(props) {
    const classes = useStyles();
    const {rows, tableHeaders, tableKeys, loading} = props;
    const [page, setPage] = React.useState(1);
    const rowsPerPage = 5;
    const pageCount = Math.ceil(rows.length / rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const renderRows = () => {
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return rows.slice(startIndex, endIndex).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
                {tableKeys.map((item, index) => (
                    <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        sx={{ borderWidth:'1px 1px 1px 1px', borderColor:'#5B5B5B', borderRight: "1px solid #5B5B5B" }}
                    >
                        {item === "sNo" ? startIndex + rowIndex + 1 : row[item]}
                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                    <TableHead sx={{ background: "#DBE2EF", height:'70px' }}>
                        <TableRow className="table-header-row">
                            {tableHeaders.map((item, index) => (
                                <TableCell key={index} sx={{ borderRight: "1px solid #5B5B5B", borderBottom:'1px solid #5B5B5B' }} className="table-cell">
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell className={classes.loadingCell} colSpan={tableHeaders.length} align="center">
                                    <CircularProgress/>
                                </TableCell>
                            </TableRow>
                        ) : rows.length === 0 ? (
                            <TableRow>
                                <TableCell className={classes.noDataCell} colSpan={tableHeaders.length} align="center">
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        ) : (
                            renderRows()
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows.length > 0 && !loading && (
                <Pagination
                    sx={{marginTop: '10px'}}
                    count={pageCount}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            )}
        </React.Fragment>
    );
}
