import { Jobs, Navbar, Home, About, Courses, Footer } from "./components";
import ScrollToTopButton from "./components/ScrollToTopButton";

const ItTraining = () => {
  return (
    <>
      <ScrollToTopButton/>
      {/* <Navbar /> */}
      <Home />
      <About />
      <Jobs/>
      <Courses />
      {/* <Footer /> */}
    </>
  );
};

export default ItTraining;
