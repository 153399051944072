import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import '../CampList/CampList.css';
import { useCandidateList, useEditCandidate } from '../../queries/ManageCandidateQueries';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = [
  'S. No.', 'Name', "Father's Name", 'Aadhar No.', 'Mobile No.', 'District', 'Registration Date', 'Through', 'Status', 'Remark'
];
const tableKeys = ['sNo', 'name', 'fatherName', 'aadharNo', 'mobileNo', 'district', 'registrationDate', 'through', 'status', 'remark'];

const useStyles = makeStyles(() => ({
  textField: {
    width: '45%',
    marginTop: '45px',
    marginBottom: '39px',
  },
}));

export default function ViewCandidates() {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: candidates, error, refetch, isLoading } = useCandidateList();
  const editEventMutation = useEditCandidate();

  useEffect(() => {
    if (candidates?.length) {
      setRowData(candidates.map((candidate, index) => ({
        ...candidate,
        sNo: index + 1,
        registrationDate: candidate.registrationDate.split('T')[0],
        isEditing: false,
      })));
    }
  }, [candidates]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  const handleStatusChange = async (index, field, value) => {
    const updatedRowData = [...rowData];
    const candidate = updatedRowData[index];
    candidate[field] = value;
    setRowData(updatedRowData);

    try {
      await editEventMutation.mutateAsync({ id: candidate._id, data: { [field]: value } });
      HandleSuccessToast('Edited successfully');
      refetch();
    } catch (error) {
      console.error('Error updating data:', error);
      updatedRowData[index][field] = rowData[index][field];
      setRowData([...rowData]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRowData = rowData.filter((row) =>
    row.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='cl-main-container'>
      <TextField
        className={classes.textField}
        variant='outlined'
        placeholder='Search by name'
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          style: { borderColor: '#707C8B', background: '#ffffff', borderRadius: '10px', height: '46px', border: '1px solid #707C8B' },
          endAdornment: (
            <InputAdornment>
              <SearchIcon size='small' />
            </InputAdornment>
          ),
        }}
        sx={{
          "& label.Mui-focused": {
            color: "#707C8B",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },
          },
        }}
      />
      <DownloadCSV headers={tableHeaders} data={rowData} filename="candidates.csv" />
      <CustomTable
        rows={filteredRowData.map((candidate, index) => ({
          ...candidate,
          status: (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={candidate.status}
                onChange={(e) => handleStatusChange(index, 'status', e.target.value)}
                label="Status"
              >
                <MenuItem value="registered">Registered</MenuItem>
                <MenuItem value="moved">Moved</MenuItem>
                <MenuItem value="underReview">Under Review</MenuItem>
                <MenuItem value="under process">Under Process</MenuItem>
                <MenuItem value="selected">Selected</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="enrolled">Enrolled</MenuItem>
              </Select>
            </FormControl>
          ),
          through: (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Through</InputLabel>
              <Select
                value={candidate.through}
                onChange={(e) => handleStatusChange(index, 'through', e.target.value)}
                label="Through"
              >
                <MenuItem value="mobiliser">Mobiliser</MenuItem>
                <MenuItem value="swalambi">Swalambi</MenuItem>
                <MenuItem value="self">Self</MenuItem>
              </Select>
            </FormControl>
          ),
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  )
}
