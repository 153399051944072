import {useQuery} from "@tanstack/react-query";
import useAPI from "../services/axios";
import queryKeys from "../services/queryKeys";

const GetCandidateBoxNumber = async() => {
    const data= await useAPI({ method: 'GET', url: '/candidates/categorize/status' });
    return data;
  };
  
  export const useCandidateBoxNumber = () => {
    return useQuery({
      queryKey: [queryKeys.GET_CANDIDATE_BOXNUMBER],
      queryFn: GetCandidateBoxNumber,
      enabled:true
    });
  };

  const GetMobiliserStatus = async() => {
    const data= await useAPI({ method: 'GET', url: '/mobilisers/status' });
    return data;
  };
  
  export const useMobiliserStatus = () => {
    return useQuery({
      queryKey: [queryKeys.GET_MOBILISER_STATUS],
      queryFn: GetMobiliserStatus,
      enabled:true
    });
  };

  // const GetCenterwiseTableData = async() => {
  //   const data= await useAPI({ method: 'GET', url: '/camps/report' });
  //   return data;
  // };
  
  // export const useCenterwiseTableData = () => {
  //   return useQuery({
  //     queryKey: [queryKeys.GET_CENTERWISE_TABLEDATA],
  //     queryFn: GetCenterwiseTableData,
  //     enabled:true
  //   });
  // };

  const GetYearwiseData = async() => {
    const data= await useAPI({ method: 'GET', url: '/candidates/categorize/year' });
    return data;
  };
  
  export const useYearwiseData = () => {
    return useQuery({
      queryKey: [queryKeys.GET_YEARWISE_DATA],
      queryFn: GetYearwiseData,
      enabled:true
    });
  };

  const GetJobRolewiseData = async() => {
    const data= await useAPI({ method: 'GET', url: '/candidates/categorize/jobRole' });
    return data;
  };
  
  export const useJobRolewiseData = () => {
    return useQuery({
      queryKey: [queryKeys.GET_JOBROLEWISE_DATA],
      queryFn: GetJobRolewiseData,
      enabled:true
    });
  };