import React, { useState } from 'react';
import { Box, Button} from '@mui/material';
import CustomTextField from '../TextField/TextField';
import '../../components/LeaderRegistration/LeaderRegistration.css';
import AutoComplete from '../AutoComplete/AutoComplete';
import { statusOptions, paymentStatus } from '../dropdownOptions';
import HandleSuccessToast from '../../helpers/HandleSuccessToast'
import HandleErrorToast from '../../helpers/HandleErrorToast';

export default function SupportPayments  () {

  const initialFormData ={
    nameOfCandidate:'',
    fatherName: '',
    candidateId:'',
    dateOfMovement: '',
    jobRole:'',
    centerLocation:'',
    nameOfMob:'',
    involvementOfDsm:'',
    nameOfDsm: '',
    involvementOfZsm:'',
    nameOfZsm: '',
    status: 'selected',
    reason: '',
    batchNo:'',
    paymentStatus:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [resetValue, setResetValue] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    try {
      const token = localStorage.getItem("token");
      if (!token) {
      HandleErrorToast("Token not found");
      return;
    }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments`, {
        method: 'POST',
        headers : {
          "Authorization": `Bearer ${JSON.stringify({
            token:localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      if(response.status===201){
      console.log('Form submitted successfully', response);
      HandleSuccessToast("Approved successfully");
      setFormData(initialFormData);
      setResetValue(!resetValue);
      }
      else if(response.status===403){
        HandleErrorToast('Session expired, Login again!');
        window.localStorage.clear();
        window.location.reload();
      }
      else {
        HandleErrorToast('Something went wrong');
      }
      
    } catch (error) {
      console.error('Error submitting form:', error);
      HandleErrorToast('Something went wrong');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    console.log(value);
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.label,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', 
      }));
    }
  };

  return (
    <div className='lr-main-container'>
      <h3 className='lr-heading'>If Payment is pending then fill this form</h3>
      <Box className='lr-inputs-container' sx={{ gap: '20px' }}>
        <CustomTextField label='Name Of Candidate' name="nameOfCandidate"  value={formData.nameOfCandidate} handleChange={handleChange}/>
        <CustomTextField label="Father's Name" name="fatherName" value={formData.fatherName} handleChange={handleChange}/>
        <CustomTextField label="Candidate ID" name="candidateId" value={formData.candidateId} handleChange={handleChange}/>
        <Box className='date-time-container'>
          <CustomTextField label='Date Of Movement' type='date' styles={{width:'48%'}} name="dateOfMovement" value={formData.dateOfMovement} handleChange={handleChange} />
          <CustomTextField label='Job Role'  styles={{width:'48%'}} name="jobRole" value={formData.jobRole} handleChange={handleChange} />
        </Box>
        <CustomTextField label='Center Location'  name="centerLocation"  value={formData.centerLocation} handleChange={handleChange} />
        <CustomTextField label='Name of Mobiliser' name="nameOfMob" value={formData.nameOfMob} handleChange={handleChange} />
        <Box className='date-time-container'>
          <CustomTextField label='Involment Of DSM (Y/N)' styles={{width:'48%', marginBottom: '24px'}} name="involvementOfDsm" value={formData.involvementOfDsm} handleChange={handleChange} />
          <CustomTextField label='Name of DSM'  styles={{width:'48%',  marginBottom: '24px'}} name="nameOfDsm" value={formData.nameOfDsm} handleChange={handleChange} />
          </Box>
          <Box className='date-time-container'>
          <CustomTextField label='Involment Of ZSM (Y/N)' styles={{width:'48%'}} name="involvementOfZsm" value={formData.involvementOfZsm} handleChange={handleChange} />
          <CustomTextField label='Name of ZSM' styles={{width:'48%'}} name="nameOfZsm" value={formData.nameOfZsm} handleChange={handleChange} />
        </Box>
        <AutoComplete label='Status' styles={{width:'50%', marginBottom:"5px"}} name='status' value={formData.status}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} options={statusOptions}/>
        {formData.status === 'selected' ? (
          <>
            <CustomTextField label="Batch Number" name="batchNo" type='number' value={formData.batchNo} handleChange={handleChange} />
          </>
        ) : (
          <>
            <CustomTextField label="Reason"  name="reason" value={formData.reason} handleChange={handleChange}/>
          </>
        )}
        <AutoComplete label='Payment Status' styles={{width:'50%', marginBottom:"5px"}} name='paymentStatus' value={formData.paymentStatus}
          options={paymentStatus}    handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue}/>
        
      </Box>
        <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
          <Button variant="contained" className='lr-submit-btn' onClick={handleFormSubmit}>
            Approve
          </Button>
      </Box>
    </div>
  )
}
