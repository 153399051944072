import React,{useEffect,useState} from 'react'
import axios from 'axios';
import CustomTable from '../../components/CustomTable/CustomTable'
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import '../../components/CampList/CampList.css'

const tableHeaders = [
  'S.No', 'Name', "Father's Name", 'Aadhar No.', 'Mobile No.', 'Address', 'District', 'Status', 'Action'
];
const tableKeys = ['sNo', 'name', 'fatherName', 'aadharNo', 'mobileNo', 'address', 'district', 'status', 'action'];

const useStyles = makeStyles(() => ({
  textField:{
    width:'45%',
    marginTop:'45px',
    marginBottom:'39px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
  },
}));


export default function MobiliserList() {
  const classes = useStyles()
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const headers = {
    "Authorization": `Bearer ${JSON.stringify({
      token:localStorage.getItem("token")
    })}`,
    "Content-Type": "application/json"
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/mobilisers`, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataWithSNo = data.map((row, index) => ({ ...row, sNo: index + 1 }));
      setRowData(dataWithSNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (index, field, value) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index][field] = value;
      return updatedCandidates;
    });
  };

  const handleEdit = (index) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = true;
      return updatedCandidates;
    });
  };

  const handleSave = async (index) => {
    try {
      const updatedCandidate = rowData[index];
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/mobilisers/${updatedCandidate._id}`, updatedCandidate, {headers});
      if (response.status !== 200) {
        throw new Error('Error updating mobiliser');
      }
      setRowData(prevCandidates => {
        const updatedCandidates = [...prevCandidates];
        // Reset isEditing flag for all fields in the row
        updatedCandidates[index].isEditing = false;
        return updatedCandidates;
      });
    } catch (error) {
      console.error('Error updating mobiliser:', error);
    }
  };

  const handleCancel = (index) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = false;
      // If you want to revert changes made during editing, you can fetch data again
      fetchData();
      return updatedCandidates;
    });
  };

  const handleView = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleCloseModal = () => {
    setSelectedCandidate(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCandidates = rowData.filter((row) =>
  row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  row.registrationNo.toLowerCase().includes(searchQuery.toLowerCase())
);


  return (
    <div className='cl-main-container'>
       <TextField
        className={classes.textField}
        variant='outlined'
        placeholder='Search By Name Or Registration No'
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          style:{borderColor:'#707C8B', background:'#ffffff', borderRadius:'10px', height:'46px', border:'1px solid #707C8B'},
          endAdornment: (
            <InputAdornment>
              <SearchIcon  size='small'/>
            </InputAdornment>
          ),
        }}
        sx={{
          "& label.Mui-focused": {
            color: "#707C8B",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },
          },
        }}
      />

      <CustomTable
        rows={filteredCandidates.map((candidate, index) => ({
          ...candidate,
          status:  candidate.isEditing ? (
            <FormControl variant="outlined" style={{ width: '110px'}}>
              <InputLabel>Status</InputLabel>
              <Select
          value={candidate.status}
          onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
          label="Status"
        >
               <MenuItem value="active">Active</MenuItem>
                <MenuItem value="terminated">Terminated</MenuItem>
                <MenuItem value="in-active">In-Active</MenuItem>
              </Select>
            </FormControl>
          ) : candidate.status,
          name: candidate.isEditing ? (
            <TextField
              value={candidate.name}
              onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
            />
          ) : candidate.name,
          action: candidate.isEditing ? (
            <div>
              <button onClick={() => handleSave(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Save</button>
              <button onClick={() => handleCancel(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Cancel</button>
            </div>
          ) : (
            <div>
              <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
              <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => handleView(candidate)} />
            </div>
          )
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
        loading={loading}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!!selectedCandidate}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 80000,
          },
        }}
      >
        <Fade in={!!selectedCandidate}>
          <div className={classes.modalContent}>
            <h2 id="transition-modal-title" className='modal-content'>Mobiliser Details</h2>
            {selectedCandidate && (
              <div>
                <p className='modal-details'><strong>Name: </strong> {selectedCandidate.name}</p>
                <p className='modal-details'><strong>Registration No.: </strong> {selectedCandidate.registrationNo}</p>
                <p className='modal-details'><strong>Father's Name: </strong> {selectedCandidate.fatherName}</p>
                <p className='modal-details'><strong>DOB: </strong>{selectedCandidate.dob}</p>
                <p className='modal-details'><strong>DOR: </strong>{selectedCandidate.dateOfRegistration}</p>
                <p className='modal-details'><strong>Mobile Number: </strong> {selectedCandidate.mobileNo}</p>
                <p className='modal-details'><strong>Email Id: </strong> {selectedCandidate.emailId}</p>
                <p className='modal-details'><strong>Aadhar Number:</strong> {selectedCandidate.aadharNo}</p>
                <p className='modal-details'><strong>Pan Number:</strong> {selectedCandidate.panNo}</p>
                <p className='modal-details'><strong>Address: </strong> {selectedCandidate.address}</p>
                <p className='modal-details'><strong>Ward Number: </strong> {selectedCandidate.wardNumber}</p>
                <p className='modal-details'><strong>Block: </strong> {selectedCandidate.block}</p>
                {/* <p className='modal-details'><strong>Zone: </strong> {selectedCandidate.zone}</p> */}
                <p className='modal-details'><strong>Panchayat: </strong> {selectedCandidate.panchayat}</p>
                <p className='modal-details'><strong>District: </strong> {selectedCandidate.district}</p>
                <p className='modal-details'><strong>State: </strong> {selectedCandidate.state}</p>
                <p className='modal-details'><strong>Post: </strong> {selectedCandidate.post}</p>
                <p className='modal-details'><strong>Aadhar Image: </strong>{selectedCandidate.aadharPhoto}</p>
                <p className='modal-details'><strong>Photograph: </strong>{selectedCandidate.photo}</p>
                <p className='modal-details'><strong>Pan Card: </strong>{selectedCandidate.panPhoto}</p>
                <p className='modal-details'><strong>Bank Passbook:</strong>{selectedCandidate.bankAccountPhoto}</p>
                <p className='modal-details'><strong>Status: </strong>{selectedCandidate.status}</p>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
