import {useQuery ,useMutation} from "@tanstack/react-query";
import useAPI from "../services/axios";
import queryKeys from "../services/queryKeys";

const GetPaymentList = async() => {
    const data= await useAPI({ method: 'GET', url: '/candidates/payments' });
    return data;
  };
  
  export const usePaymentList = () => {
    return useQuery({
      queryKey: [queryKeys.GET_PAYMENT_LIST],
      queryFn: GetPaymentList,
      enabled:true
    });
  };
  
  const EditPayment = ({ id, data }) => {
    return useAPI({ method: "PUT", url: `/candidates/payments/${id}`, data });
  };
  
  export const useEditPayment = () => {
    return useMutation({
      mutationFn: (Payment) => EditPayment(Payment),
    });
  };
  
  const GetPaymentStatusList = async (status) => {
    const data = await useAPI({ method: 'GET', url: `/payments/${status}` });
    return data;
  };
  
  export const usePaymentStatusList = (status) => {
    return useQuery({
      queryKey: [queryKeys.GET_PAYMENT_STATUS_LIST, status],
      queryFn: () => GetPaymentStatusList(status),
      enabled: !!status
    });
  };