import React, { useState, useEffect } from 'react';
import { Box, Button, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import CustomTextField from '../TextField/TextField';
import AutoComplete from '../AutoComplete/AutoComplete';
import { candidateStatusOptions, genderOptions, jobroleOptions, stateOptions, trainingCenterOptions } from '../dropdownOptions';
import PhoneNumberField from '../PhoneNumberField';

const EditCandidateModal = ({ candidate, onSave, onCancel }) => {
  const initialFormData = {
    name: '',
    campName: '',
    fatherName: '',
    motherName: '',
    educationLevel: '',
    gender: '',
    dob: '',
    age: '',
    mobileNo: '',
    guardianMobileNo: '',
    aadharNo: '',
    source: '',
    addressType: 'Urban',
    address: '',
    wardNumber: null,
    block: '',
    landmark: '',
    district: '',
    state: '',
    village: '',
    panchayat: '',
    postOffice: '',
    preferredJobRole: '',
    photo: null,
    aadharImage: null,
    status:'',
    trainingCentre:'',
    anyQuery: '',
    stakeHolders: [{ name: '', post: '',sid: '' }],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [campOptions, setCampOptions] = useState([]);

  useEffect(() => {
    const fetchCampOptions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/camps`, {
          headers: {
            Authorization: `Bearer ${JSON.stringify({ token: localStorage.getItem('token') })}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          const options = data.map((item) => item.schemeName);
          setCampOptions(options);
        } else {
          console.error('Failed to fetch camp options');
        }
      } catch (error) {
        console.error('Error fetching camp options:', error);
      }
    };

    fetchCampOptions();
  }, []);

  useEffect(() => {
    if (candidate) {
      setFormData({ ...initialFormData, ...candidate, stakeHolders: candidate.stakeHolders || [{ name: '', post: '', sid: '' }] });
    }
  }, [candidate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    if (value) {
      let selectedValue = value.label || value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '',
      }));
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  const handleDOBChange = (event) => {
    const dobValue = event.target.value;
    setFormData({ ...formData, dob: dobValue, age: calculateAge(dobValue) });
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, photo: file });
    }
  };

  const handleAadharImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, aadharImage: file });
    }
  };

  const handleStakeholderChange = (index, event) => {
    const { name, value } = event.target;
    const updatedStakeholders = formData.stakeHolders.map((stakeholder, idx) =>
      idx === index ? { ...stakeholder, [name]: value } : stakeholder
    );
    setFormData({ ...formData, stakeHolders: updatedStakeholders });
  };

  const addStakeholder = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      stakeHolders: [...prevFormData.stakeHolders, { name: '', post: '', id: '' }],
    }));
  };

  const handleSave = () => {
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'createdBy') {
        continue;
      }
      if (key === 'stakeHolders') {
        formData.stakeHolders.forEach((stakeholder, index) => {
          formDataToSend.append(`stakeHolders[${index}][name]`, stakeholder.name);
          formDataToSend.append(`stakeHolders[${index}][post]`, stakeholder.post);
          formDataToSend.append(`stakeHolders[${index}][sid]`, stakeholder.sid);
        });
      } else if (key === 'wardNumber') {
        const value = formData[key];
        formDataToSend.append(key, value === '' ? null : Number(value));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    onSave(formDataToSend);
  };


  return (
    <Box>
      <h3 className="r-heading">Basic Details</h3>
      <Box className="r-inputs-container" sx={{ gap: '20px' }}>
        <CustomTextField label="Name" name="name" value={formData.name} handleChange={handleChange} />
        <CustomTextField label="Source" name="source" value={formData.source} handleChange={handleChange} />
        <AutoComplete
          label="Which camp do you belong to?"
          name="campName"
          value={formData.campName}
          options={campOptions}
          handleAutocompleteChange={handleAutocompleteChange}
          styles={{ width: '50%'}}
        />
        <CustomTextField label="Father's Name" name="fatherName" value={formData.fatherName} handleChange={handleChange} />
        <CustomTextField label="Mother's Name" name="motherName" value={formData.motherName} handleChange={handleChange} />
        <CustomTextField label="Education Level" name="educationLevel" value={formData.educationLevel} handleChange={handleChange} />
        <AutoComplete
          label="Gender"
          name="gender"
          value={formData.gender}
          options={genderOptions}
          styles={{ width: '50%'}}
          handleAutocompleteChange={handleAutocompleteChange}
        />
          <CustomTextField
            label="DOB"
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleDOBChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CustomTextField
            label="Age"
            name="age"
            value={formData.age}
            InputProps={{
              readOnly: true,
            }}
          />
        <PhoneNumberField
          label="Mobile Number"
          name="mobileNo"
          value={formData.mobileNo}
          handleChange={(value) => handlePhoneNumberChange('mobileNo', value)}
        />
        <PhoneNumberField
          label="Guardian Mobile Number"
          name="guardianMobileNo"
          value={formData.guardianMobileNo}
          handleChange={(value) => handlePhoneNumberChange('guardianMobileNo', value)}
        />
        <CustomTextField label="Aadhar No." type="number" name="aadharNo" value={formData.aadharNo} handleChange={handleChange} />
      </Box>
      <h3 className="r-heading">Stakeholder Details</h3>
      <Box className="r-inputs-container" sx={{ gap: '20px' }}>
        {formData.stakeHolders && formData.stakeHolders.map((stakeholder, index) => (
          <Box key={index} className="r-inputs-container" sx={{ gap: '20px' }}>
            <CustomTextField
              label="Name of Stakeholder"
              name="name"
              value={stakeholder.name}
              handleChange={(event) => handleStakeholderChange(index, event)}
            />
            <CustomTextField
              label="Post Of Stakeholder"
              name="post"
              value={stakeholder.post}
              handleChange={(event) => handleStakeholderChange(index, event)}
            />
            <CustomTextField
              label="ID Of Stakeholder"
              name="sid"
              value={stakeholder.sid}
              handleChange={(event) => handleStakeholderChange(index, event)}
            />
          </Box>
        ))}
        <Button variant="contained" onClick={addStakeholder}>
          Add
        </Button>
      </Box>
      <h3 className="r-heading">Address</h3>
      <Box className="r-inputs-container" sx={{ gap: '20px' }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="addressType"
          value={formData.addressType}
          onChange={(event) => handleChange(event)}
          sx={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}
        >
          <FormControlLabel
            value="Rural"
            control={<Radio />}
            label="Rural"
            sx={{ fontFamily: 'Inter !important', fontSize: '20px', color: '#000' }}
          />
          <FormControlLabel
            value="Urban"
            control={<Radio />}
            label="Urban"
            sx={{ fontFamily: 'Inter !important', fontSize: '20px', color: '#000' }}
          />
        </RadioGroup>
        {formData.addressType === 'Urban' ? (
          <>
            <CustomTextField label="Address" name="address" multiline={true} value={formData.address} handleChange={handleChange} />
            <CustomTextField label="Ward Number" name="wardNumber" type='number' value={formData.wardNumber} handleChange={handleChange} />
          </>
        ) : (
          <>
            <CustomTextField label="Village" multiline={true} name="village" value={formData.village} handleChange={handleChange} />
            <CustomTextField label="Panchayat" name="panchayat" value={formData.panchayat} handleChange={handleChange} />
            <CustomTextField label="Post Office" name="postOffice" value={formData.postOffice} handleChange={handleChange} />
          </>
        )}
        <CustomTextField label="District" name="district" value={formData.district} handleChange={handleChange} />
        <AutoComplete
          label="State"
          name="state"
          value={formData.state}
          options={stateOptions}
          styles={{ width: '50%'}}
          handleAutocompleteChange={handleAutocompleteChange}
        />
        <CustomTextField label="Block" name="block" value={formData.block} handleChange={handleChange} />
        <CustomTextField label="Landmark" name="landmark" value={formData.landmark} handleChange={handleChange} />
        <AutoComplete
          label="Preferred Job Role"
          name="preferredJobRole"
          value={formData.preferredJobRole}
          options={jobroleOptions}
          styles={{ width: '50%'}}
          handleAutocompleteChange={handleAutocompleteChange}
        />
          <CustomTextField
            label="Photo"
            type="file"
            // value={formData.photo}
            name="photo"
            onChange={handlePhotoChange}
          />
          <CustomTextField
            label="Aadhar"
            type="file"
            // value={formData.aadharImage}
            name="aadharImage"
            onChange={handleAadharImageChange}
          />
          <AutoComplete
          label="Status"
          name="status"
          value={formData.status}
          options={candidateStatusOptions}
          styles={{ width: '50%'}}
          handleAutocompleteChange={handleAutocompleteChange}
        />
        {formData.status === 'moved' ? (
          <>
          <AutoComplete
          label="Training Center"
          name="trainingCentre"
          options={trainingCenterOptions}
          styles={{ width: '50%'}}
          handleAutocompleteChange={handleAutocompleteChange}/>
          </>
        ) : (
          <></>
        )}
        <CustomTextField label="Any Query?" multiline={true} name="anyQuery" value={formData.anyQuery} handleChange={handleChange} />
      </Box>
      <Box sx={{ display:'flex',justifyContent:'center',marginTop:'30px'}}>
        <Button variant="contained" onClick={handleSave} style={{marginRight:'20px',width:'85px'}}>
          Save
        </Button>
        <Button variant="contained" onClick={onCancel} style={{width:'85px'}}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditCandidateModal;
