import React, { useEffect, useState } from 'react';
import './MultiHeaderTable.css';

const TrainingCenterTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('backend-api');
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const jobRoles = [
    "Fitter Fabrication",
    "Automotive Welding Machine Operator (Manual and Robotics)",
    "Assistant Electrician",
    "Automotive Electrician",
    "Soler PV Installer (Suryamitra)",
    "Smartphone Assembly Technician",
    "Telecom Customer Care Executive - Call Center/Relationship Center",
    "Fashion Designer",
    "Domestic Data Entry Operator",
    "Sewing Machine Operator",
    "Self Employed Tailor",
    "General Duty Assistant",
    "Plumber General",
    "Retails Sales Executive",
    "User Experience Designer",
    "Marketing Operations Analysis",
    "Data Associate",
    "Media Developer",
    "Domestic IT Helpdesk Attendant",
    "Technical Support Engineer"
  ];

  return (
  <div className='scroll-table'>
    <div className="table-container">
      <table className="multiheader-table">
        <thead>
          <tr>
            <th rowSpan={2}>S.No.</th>
            <th rowSpan={2}>Center Location</th>
            <th colSpan={jobRoles.length}>Job Role</th>
          </tr>
          <tr>
            {jobRoles.map((role, index) => (
              <th key={index}>{role}</th>
            ))}
          </tr>
        </thead>
        {loading ? (
          <p>Loading...</p>
        ) : data.length === 0 ? (
          <tbody style={{ height: '200px' }}>
            <tr>
              <td colSpan={jobRoles.length + 2} align="center">No data available</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.centerLocation}</td>
                {jobRoles.map((role, roleIndex) => (
                  <td key={roleIndex}>{row.jobRole[role]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  </div>
  );
}

export default TrainingCenterTable;
