import React,{useEffect,useState} from 'react'
import CustomTable from '../../components/CustomTable/CustomTable'
import '../CampList/CampList.css'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useEditPayment, usePaymentList } from '../../queries/ManagePaymentQueries';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = ['S. No.', 'Name', "Father's Name", 'Aadhar No.','Mobile No.' ,'District','Registration Date','Name of (Mobiliser/Swalambi)', 'Status','Remarks']
const tableKeys = ['sNo', 'nameOfCandidate', 'fatherName', 'aadharNo','mobileNo' ,'district', 'registrationDate','nameOfMs','paymentStatus','remark'];

export default function PaymentView() {
  const [rowData, setRowData] = useState([]);
  const { data: payments, error, isLoading } = usePaymentList();
  const editPaymentMutation = useEditPayment();
 

  useEffect(() => {
    if (payments?.length) {
      setRowData(payments.map((payment, index) => ({
        ...payment,
        sNo: index + 1,
        registrationDate: payment.registrationDate ? payment.registrationDate.split('T')[0] : 'N/A',
        isEditing: false,
      })));
    }
  }, [payments]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;


  const handleStatusChange = async (index, field, value) => {
    const updatedRowData = [...rowData];
    const payment = updatedRowData[index];
    payment[field] = value;
    setRowData(updatedRowData);

    try {
      await editPaymentMutation.mutateAsync({ id: payment._id, data: { [field]: value } });
    } catch (error) {
      console.error('Error updating data:', error);
      updatedRowData[index][field] = rowData[index][field];
      setRowData([...rowData]);
    }
  };
  
  return (
    <div className='cl-main-container'>
       <h3 className='cl-heading' style={{marginTop:'25px'}}>Payment List</h3>
       <DownloadCSV headers={tableHeaders} data={rowData} filename="payment.csv" />
       <CustomTable
         rows={rowData.map((payment, index) => ({
          ...payment,
          status: (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={payment.status}
                onChange={(e) => handleStatusChange(index, 'status', e.target.value)}
                label="Status"
              >
                <MenuItem value="partial">Partial</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          ),
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  )
}