import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CandidateUpdate from "../components/update_cand";
import Mobiliser from "../pages/Mobiliser/Mobiliser";
import Leader from "../pages/Leader/Leader";
import Support from "../pages/Support/Support";
import HandleEvent from "../components/HandleEvent";
import UserDetails from "../components/userDetails";
import { Navigate, Route, Routes } from "react-router-dom";
import MobiliserHead from "../pages/MobliserHead/MobiliserHead";
import MuiTheme from "../theme/MuiTheme"

const PrivateRoute = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
    <Routes>
      <Route path="/userDetails" element={<UserDetails />} />
      <Route path="/candidate-update" element={<CandidateUpdate />} />
      <Route path="/mobiliser" element={<Mobiliser />} />
      <Route path="/leader" element={<Leader />} />
      <Route path="/support" element={<Support />} />
      <Route path="/head" element={<MobiliserHead/>} />
      <Route path="/edit" element={<HandleEvent />} />
      <Route path="*" element={<Navigate to="/leader" />} />
    </Routes>
    </ThemeProvider>
  );
};

export default PrivateRoute;
