import React from 'react';
import '../MultiHeaderTable/MultiHeaderTable.css';
import { Button } from '@mui/material';

const CenterwiseTable = () => {
  // const { data: tableData, error, isLoading } = useCenterwiseTableData();

  // if (isLoading) return <div>Loading...</div>;

  // if (error) {
  //   return <p>Error fetching data: {error.message}</p>;
  // }
  const tableData='';
  const downloadCSV = () => {
    const csvRows = [];
    csvRows.push('S.No.,Center Name,Registered,Selected,Enrolled,Under Review,Rejected');
    tableData.forEach((row, index) => {
      const { venue, enrolled, registered, rejected, selected, underReview } = row;
      const values = [
        index + 1,
        venue,
        registered,
        selected,
        enrolled ,
        underReview,
        rejected
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'centerwise_table_data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="table-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column',marginLeft:'2vw' }}>
        <Button variant="contained" style={{marginBottom:'25px',backgroundColor:'#112D4E',color:'white'}} onClick={downloadCSV}>
          Download CSV
        </Button>
      <table className="multiheader-table">
        <thead>
          <tr>
            <th rowSpan={2}>S.No.</th>
            <th rowSpan={2}>Center Name</th>
            <th colSpan={5}>Candidates</th>
          </tr>
          <tr>
            <th>Registered</th>
            <th>Selected</th>
            <th>Enrolled</th>
            <th>Under Review</th>
            <th>Rejected</th>
          </tr>
        </thead>
        {tableData.length === 0 ? (
          <tbody style={{ height: '200px' }}>
            <tr><td colSpan={7} align="center">No data available</td></tr>
          </tbody>
        ) : (
          <tbody>
            {tableData.map((row, index) => {
              const { venue, enrolled, registered, rejected, selected, underreview } = row;
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{venue}</td>
                  <td>{registered}</td>
                  <td>{selected}</td>
                  <td>{enrolled }</td>
                  <td>{underreview}</td>
                  <td>{rejected}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CenterwiseTable;
