import { useMutation, useQuery } from "@tanstack/react-query";
import useAPI from "../services/axios";
import queryKeys from "../services/queryKeys";

const AddMobiliser = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach(file => {
        formData.append(key, file); 
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return useAPI({ 
    method: "POST", 
    url: "/mobilisers", 
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const useAddMobiliser = () => {
  return useMutation({
    mutationFn: (data) => AddMobiliser(data),
  });
};

const GetMobilisersList = async () => {
  const data = await useAPI({ method: 'GET', url: '/mobilisers' });
  return data;
};

export const useMobilisersList = () => {
  return useQuery({
    queryKey: [queryKeys.GET_MOBILISER_LIST],
    queryFn: GetMobilisersList,
    enabled: true
  });
};

const EditMobiliser = async (data) => {
  const formData = new FormData();
  Object.keys(data.data).forEach(key => {
    if (Array.isArray(data.data[key])) {
      data.data[key].forEach(file => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, data.data[key]);
    }
  });

  return useAPI({ 
    method: "PUT", 
    url: `/mobilisers/${data.id}`, 
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const useEditMobiliser = () => {
  return useMutation({
    mutationFn: (data) => EditMobiliser(data),
  });
};

const DeleteMobiliser = (id) => {
  return useAPI({ method: "DELETE", url: `/mobilisers/${id}` });
};

export const useDeleteMobiliser = () => {
  return useMutation({
    mutationFn: (id) => DeleteMobiliser(id),
  });
};
