import React, { useState } from "react";
import "./login.css";
import "../../components/css/util.css";
import "../../components/css/main.css";
import HandleSuccessToast from "../../helpers/HandleSuccessToast";
import HandleErrorToast from "../../helpers/HandleErrorToast";
import { Grid, TextField, Typography} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { IconButton } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { jwtDecode } from "jwt-decode";


export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  function handleSubmit(e) {
    e.preventDefault();
  
    fetch(`${process.env.REACT_APP_API_URL}/employees/login`, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          HandleSuccessToast("Login successful");
          const token = data.data;
          localStorage.setItem("token", token); 
          let userType = "";
          if (token) {
            const decodedToken = jwtDecode(token);
            userType = decodedToken.role;
            console.log("User type: ", userType);
          }
          if (userType === "mobiliser") {
            window.location.href = "/mobiliser";
          } else if (userType === "leader") {
            window.location.href = "/leader";
          } else if (userType === "support") {
            window.location.href = "/support";
          } else {
            window.location.href = "/head";
          }
        } else {
          HandleErrorToast(data.error || 'Something went wrong');
        }
      })
      .catch((error) => {
        HandleErrorToast('Network error');
      });
  }

  return (
    <div className="login-container">
      <form className="login-box" onSubmit={handleSubmit}>
        <Grid align="center">
              <Avatar sx={{ bgcolor: '#5c1114' }}>
                <LockOutlinedIcon />
            </Avatar>
        </Grid>
        <Typography component="h1" variant="h5" align="center" sx={{mb:3, mt:1}} className="!text-red-500">
            Log in
          </Typography>
        {/* <Grid sx={{mb:3}} align='center'>
            <Autocomplete
              value={userType}
              disablePortal
              id="combo-box-demo"
              options={userTypeList}
              sx={{ width: '80%' }}
              onChange={e=> setUserType(e.target.textContent)}
              renderInput={(params) => <TextField {...params} label="User Type" required/>}
            />
        </Grid> */}
        <Grid item xs={12}  sx={{'data-replicate': 'true', mb:3}}>
            <TextField
              required
              sx={{width:'80%', height:""}}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
        </Grid> 
        <Grid item xs={12}>
            <FormControl style={{display:'flex',alignItems:'center',justifyContent:'center'}} variant="outlined">
            <InputLabel style={{marginLeft:'50px'}} htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
            id="outlined-adornment-password"
            size="lg"
            name="password"
            required
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: '80%',
              height: "",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            autoComplete="new-password"
          />
          </FormControl>
          </Grid>   
        <div>
          <button className="login-btn">Login</button>
        </div>

        {/* <Grid container justifyContent='space-around'>
          <Grid item>
            <Link href="#" variant="body2" style={{textDecoration:'none'}}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/sign-up" variant="body2" style={{textDecoration:'none'}}>
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </form>
    </div>
  );
}

