import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './MobiliserPayments.css'
import CustomTable from '../CustomTable/CustomTable'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';

const tableHeaders = [
  'Serial Number', 'Candidate ID', 'Name', "Father's Name", 'Status', 'Action'
]
const tableKeys = ['sNo', 'candidateId', 'name', 'fatherName','paymentStatus','action'];

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
  },
}));


export default function MobiliserPayments() {
  const classes = useStyles()
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/candidates/selected`, {
        headers : {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataWithSNo = data.map((row, index) => ({ ...row, sNo: index + 1 }));
      setRowData(dataWithSNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false);
    }
  };


  const handleFieldChange = (index, field, value) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index][field] = value;
      return updatedCandidates;
    });
  };

  const handleEdit = (index) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = true;
      return updatedCandidates;
    });
  };

  const headers = {
    "Authorization": `Bearer ${JSON.stringify({
      token: window.localStorage.getItem("token")
    })}`,
    "Content-Type": "application/json"
  }

  const handleSave = async (index) => {
    try {
      const updatedCandidate = rowData[index];
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/candidates/${updatedCandidate._id}`, updatedCandidate, {headers});
      if (response.status !== 200) {
        throw new Error('Error updating candidate');
      }
      setRowData(prevCandidates => {
        const updatedCandidates = [...prevCandidates];
        updatedCandidates[index].isEditing = false;
        return updatedCandidates;
      });
    } catch (error) {
      console.error('Error updating candidate:', error);
    }
  };

  const handleCancel = (index) => {
    setRowData(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = false;
      // If you want to revert changes made during editing, you can fetch data again
      fetchData();
      return updatedCandidates;
    });
  };

  const handleView = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleCloseModal = () => {
    setSelectedCandidate(null);
  };


  return (
    <div className='p-main-container'>
      <h3 className='p-heading'>Payments Records</h3>
      <CustomTable
       rows={rowData.map((candidate, index) => ({
        ...candidate,
        action: candidate.isEditing ? (
          <div>
            <button onClick={() => handleSave(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Save</button>
            <button onClick={() => handleCancel(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Cancel</button>
          </div>
        ) : (
          <div>
            <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
            <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => handleView(candidate)} />
          </div>
        ),
        paymentStatus: candidate.isEditing ? (
          <FormControl variant="outlined" style={{width:'110px'}}>
            <InputLabel>Status</InputLabel>
            <Select
            value={candidate.paymentStatus}
            onChange={(e) => handleFieldChange(index, 'paymentStatus', e.target.value)}
            label="Status"
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="partial">Partial</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </Select>
          </FormControl>
        ) : candidate.paymentStatus,
        name: candidate.isEditing ? (
          <TextField
            value={candidate.name}
            onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
          />
        ) : candidate.name,
        fatherName: candidate.isEditing ?(
          <TextField
            value={candidate.fatherName}
            onChange={(e) => handleFieldChange(index, 'fatherName', e.target.value)}
          />
        ) : candidate.fatherName
      }))}
      tableHeaders={tableHeaders} tableKeys={tableKeys} loading={loading}/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!!selectedCandidate}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 80000,
          },
        }}
      >
        <Fade in={!!selectedCandidate}>
          <div className={classes.modalContent}>
            <h2 id="transition-modal-title" className='modal-content'>Candidate Details</h2>
            {selectedCandidate && (
               <div>
               <p className='modal-details'><strong>Name: </strong> {selectedCandidate.name}</p>
               <p className='modal-details'><strong>Candidate ID: </strong> {selectedCandidate.candidateId}</p>
               <p className='modal-details'><strong>Father's Name: </strong> {selectedCandidate.fatherName}</p>
               <p className='modal-details'><strong>Mother's Name: </strong> {selectedCandidate.motherName}</p>
               <p className='modal-details'><strong>DOB: </strong>{selectedCandidate.dob}</p>
               <p className='modal-details'><strong>Age: </strong>{selectedCandidate.age}</p>
               <p className='modal-details'><strong>Education Level: </strong>{selectedCandidate.educationLevel}</p>
               <p className='modal-details'><strong>Mobile Number: </strong> {selectedCandidate.mobileNo}</p>
               <p className='modal-details'><strong>Aadhar Number:</strong> {selectedCandidate.aadharNo}</p>
               {selectedCandidate.addressType === 'Urban' ? (
               <>
                <p className='modal-details'><strong>Address: </strong> {selectedCandidate.address}</p>
                <p className='modal-details'><strong>Ward Number: </strong> {selectedCandidate.wardNumber}</p>
                <p className='modal-details'><strong>District: </strong> {selectedCandidate.district}</p>
                <p className='modal-details'><strong>State: </strong> {selectedCandidate.state}</p>
               </>
               ) : (
               <>
               <p className='modal-details'><strong>Village: </strong> {selectedCandidate.village}</p>
               <p className='modal-details'><strong>Panchayat: </strong> {selectedCandidate.panchayat}</p>
               <p className='modal-details'><strong>Post-Office: </strong> {selectedCandidate.postOffice}</p>
               </>
                )}
               <p className='modal-details'><strong>Block: </strong> {selectedCandidate.block}</p>
               <p className='modal-details'><strong>Landmark: </strong> {selectedCandidate.landmark}</p>
               <p className='modal-details'><strong>Preferred Job Role: </strong> {selectedCandidate.preferredJobRole}</p>
               <p className='modal-details'><strong>Query: </strong> {selectedCandidate.anyQuery}</p>
               <p className='modal-details'><strong>Status: </strong>{selectedCandidate.paymentStatus}</p>
             </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}


