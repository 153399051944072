import React from 'react'
import "./About.css"
import skillImg from "../../images/Skill-Development-Activities.jpg";

function About() {
  return (
    <div className='about-container'>
      <div className='img-container'>
        <img src={skillImg} alt='skill'></img>
      </div>
      <div className='skill-container'>
        <h2>About Skill Development</h2>
        <p>The skill development program is a scheme to empower the youth of the country with the skills to set or to boost their chances of getting employment in various sectors according to their skills. It also helps individuals to improve their skills as well as their productivity. Skill development efforts across the country have been in pace. India is one of the youngest countries and it enjoys its demographic advantage, it has an average age of 29 years which is very less compared to other advanced countries. With the present education and skill levels of those already in the labor force, it would be a major challenge for India to take the yield of its demographic advantage.</p>
        <p>Recent studies show that around 25% of Indian graduates are ‘employable’ in the organized sector. And the informal sector which consisted of about 93% of the total workforce has no skilling mechanism. So, there is a necessity for quick reorganization of the skill development ecosystem, which is highly required by industries. It is predicted that very soon India will become the Human Resource Capital of the world by skilling its youth. Skill development initiatives will help in enhancing the inner potential, for which the development and articulation of a national policy on skill development are already in progress.</p>
        <p>As India moves progressively towards becoming a global economy, it must meet the rising aspirations of its youth. This can be somewhat achieved by focusing on the advancement of skills that are relevant to the emerging economic environment. To create opportunities, space, and scope for the development of the talents of the Indian youth and to enhance their skills, SGRS Academic Private Limited focuses on Youth Skill Development.</p>
        <p>Skills and knowledge are the driving forces of economic growth and social development of any country. The skill development program is a scheme to empower the youth of the country with the skills to set or to boost their chances of getting employment in various sectors according to their skills. It also helps individuals to improve their skills as well as their productivity.</p>
        <p>India is currently facing a severe shortage of well-trained, skilled workers. It is estimated that only 2.3 % of the workforce in India has undergone formal skill training as compared to 68% in the UK, 75% in Germany, 52% in USA, 80% in Japan and 96% in South Korea. Even Large sections of the educated workforce have little or no job skills, which is making them largely unemployable. Skill development efforts across the country have been in pace. As India enjoys the demographic advantage of having the youngest workforce with an average age of 29 years in comparison with the advanced economies.</p>
        <p>The National Skill Development Mission was approved by the Union Cabinet on 01.07.2015, and officially launched by the Hon’ble Prime Minister on 15.07.2015 on the occasion of World Youth Skills Day. The Mission has been developed to create convergence across sectors and States in terms of skill training activities. Further, to achieve the vision of ‘Skilled India’.</p>
      </div>
      <div className='skill-container'>
        <h2>Skilling</h2>
        <p>As India moves progressively towards becoming a global economy, it must meet the rising aspirations of its youth. This can be somewhat achieved by focusing on the advancement of skills that are relevant to the emerging economic environment. To create opportunities, space, and scope for the development of the talents of the Indian youth and to enhance their skills, <strong>“SGRS Academic Private Limited”</strong> is trying their best to provide the quality as well as quantity of training for the rural Youth of the country.</p>
        <p>SGRS Academic Private Limited is proactively working under the empanelment of the Jharkhand Skill Development Mission Society to achieve the aim and objective of the skill development training program in Jharkhand. Under the skill schemes of “Deen Dayal Upadhyay Kaushal Kendra (Mega Skill Centre)” and “Saksham Jharkhand Kaushal Vikas Yojna” of Jharkhand Skill Development Mission Society, an Autonomous Institution of Dept of Labour, Employment, Training and Skill Development, Government of Jharkhand, “SGRS Academic Private Limited” is providing skill training to the youth of Jharkhand State in various sectors and job roles with a mission to bridge the demand and gap of skilled human resources in the manufacturing as well as various services sector of India.</p>
        <p>Also, SGRS Academic Private Limited is proactively working under Haryana State Rural Livelihood Mission to train and enhance the employability of rural youth of Haryana State under the scheme of DDU-GKY of the Ministry of Rural Development, Government of India.</p>
      </div>
    </div>
  )
}

export default About
      
