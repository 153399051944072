import React from 'react';
import { Box, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CustomTextField from '../TextField/TextField';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import HandleErrorToast from '../../helpers/HandleErrorToast';
import "../CreateCamp/CreateCamp.css";
import { useAddEvents } from '../../queries/ManageEventQueries';

export default function CreateEvent(props) {
  const { handleSubmit, control, reset } = useForm();
  const { mutate: addEvents, isLoading } = useAddEvents();

  const onSubmit = (formData) => {
    const dataToSend = {
      ...formData,
      photos: Array.isArray(formData.photos) ? formData.photos : [],
    };

    addEvents(dataToSend, {
      onSuccess: () => {
        HandleSuccessToast('Event added successfully');
        reset();
        props.onFormSubmitSuccess();
      },
      onError: async (error) => {
        let errorMessage = 'Something went wrong';
        if (error.response) {
          const errorText = await error.response.text();
          errorMessage = errorText || errorMessage;
        }
        HandleErrorToast(errorMessage);
      },
    });
  };

  return (
    <form className="cc-main-container" onSubmit={handleSubmit(onSubmit)}>
      <h3 className='cc-heading'>Basic Details</h3>
      <Box className='inputs-container' sx={{ gap: '20px' }}>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Title" {...field} />}
        />
        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Description" {...field} />}
        />
        <Controller
          name="date"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Date" type="date" {...field} />}
        />
        <Controller
          name="time"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Time" type="time" {...field} />}
        />
        <Controller
          name="venue"
          control={control}
          defaultValue=""
          render={({ field }) => <CustomTextField label="Venue" multiline {...field} />}
        />
      </Box>
      <h3 className='cc-heading' style={{ marginBottom: '20px' }}>Upload Four Event Photographs</h3>
      <Box className='inputs-container' sx={{ gap: '20px' }}>
        <Controller
          name="photos"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <input
              type="file"
              multiple
              onChange={(e) => {
                const files = Array.from(e.target.files);
                field.onChange(files);
              }}
            />
          )}
        />
      </Box>
      <Box sx={{ width: '100%', marginTop: '34px', marginBottom: '54px', textAlign: 'center' }}>
        <Button variant="contained" className='submit-btn' type='submit'>
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </Box>
    </form>
  );
}
