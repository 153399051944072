/* eslint-disable no-underscore-dangle */
import axios from "axios";

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use(
   async(config) => {
    const authSession = await localStorage.getItem("token");
    if (authSession) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${JSON.stringify({
          token: localStorage.getItem("token"),
        })}`,}}
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
      config.headers["content-type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

let isRefreshing = false;

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const authSession = await localStorage.getItem("token");

    if (error?.response?.status === 401 && authSession && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return Promise.reject(error);
      }

      isRefreshing = true;
      try {
        // Add logic to refresh token here
        // const response = await refreshAuthToken();
        // if (response) {
        //   localStorage.setItem("token", JSON.stringify(response.data.token));
        //   AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        //   isRefreshing = false;
        //   return AxiosInstance(originalRequest);
        // }
      } catch (err) {
        console.error(err);
        isRefreshing = false;
        // handleLogoutSession();
      }
    } else if (error?.response?.status === 401) {
      // handleLogoutSession();
    }

    return Promise.reject({
      status: error?.response?.status,
      data: error?.response?.data,
    });
  }
);

const useAPI = async (props) => {
  const { url, method = "GET", headers, ...rest } = props;
  try {
    const result = await AxiosInstance({
      url,
      method,
      headers,
      ...rest,
    });
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export default useAPI;
