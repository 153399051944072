import React from 'react';
import '../MultiHeaderTable/MultiHeaderTable.css';
import { useJobRolewiseData } from '../../queries/DashboardQueries';
import { Button } from '@mui/material';

const JobRolewiseTable = () => {
  const { data: tableData, error, isLoading } = useJobRolewiseData();

  if (isLoading) return <div>Loading...</div>;

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  const downloadCSV = () => {
    const csvRows = [];
    csvRows.push('S.No.,Job Role Name,Registered,Selected,Enrolled,Under Review,Rejected');
    Object.keys(tableData).forEach((jobRole, index) => {
      const { registered, selected, enrolled, underReview, rejected } = tableData[jobRole];
      const values = [
        index + 1,
        jobRole,
        registered,
        selected,
        enrolled,
        underReview,
        rejected
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'jobrolewise_table_data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="table-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column',marginLeft:'3vw' }}>
        <Button variant="contained" style={{marginBottom:'25px',backgroundColor:'#112D4E',color:'white'}} onClick={downloadCSV}>
          Download CSV
        </Button>
      <table className="multiheader-table">
        <thead>
          <tr>
            <th rowSpan={2}>S.No.</th>
            <th rowSpan={2}>Job Role Name</th>
            <th colSpan={5}>Candidates</th>
          </tr>
          <tr>
            <th>Registered</th>
            <th>Selected</th>
            <th>Enrolled</th>
            <th>Under Review</th>
            <th>Rejected</th>
          </tr>
        </thead>
        {Object.keys(tableData).length === 0 ? (
          <tbody style={{ height: '200px' }}>
            <tr><td colSpan={7} align="center">No data available</td></tr>
          </tbody>
        ) : (
          <tbody>
            {Object.keys(tableData).map((jobRole, index) => {
              const { registered, selected, enrolled, underReview, rejected } = tableData[jobRole];
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{jobRole}</td>
                  <td>{registered}</td>
                  <td>{selected}</td>
                  <td>{enrolled}</td>
                  <td>{underReview}</td>
                  <td>{rejected}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default JobRolewiseTable;
