import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import './CampList.css';

const tableHeaders = [
  'Scheme name', 'Sponsored agency', 'Event title', 'Date', 'Time', 'Venue', 'Guest', 'Status'
];
const tableKeys = ['schemeName', 'sponsoredAgency', 'eventTitle', 'date', 'time', 'address', 'guestName', 'status'];

const useStyles = makeStyles(() => ({
  textField: {
    width: '45%',
    marginTop: '45px',
    marginBottom: '39px',
  },
}));

export default function CampList() {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/camps`, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      const formattedData = data.map(camp => ({
        ...camp,
        date: camp.date.split('T')[0],
        address: camp?.address + ',' + camp?.block + ',' + camp?.district + ',' + camp?.panchayat + ',' + camp?.landmark,
        guestName: camp?.guestName + ',' + camp?.guestDetails
      }));
      setRowData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const headers = {
    "Authorization": `Bearer ${JSON.stringify({
      token: window.localStorage.getItem("token")
    })}`,
    "Content-Type": "application/json"
  };

  const handleStatusChange = async (index, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].status = value;
    setRowData(updatedRowData);
    try {
      const id = updatedRowData[index]._id;
      await axios.put(`${process.env.REACT_APP_API_URL}/camps/${id}`, updatedRowData[index], { headers });
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRowData = rowData.filter((row) =>
    row.eventTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='cl-main-container'>
      <TextField
        className={classes.textField}
        placeholder='Search By Event Title'
        variant='outlined'
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          style: { borderColor: '#707C8B', background: '#ffffff', borderRadius: '10px', height: '46px', border: '1px solid #707C8B' },
          endAdornment: (
            <InputAdornment>
              <SearchIcon size='small' />
            </InputAdornment>
          ),
        }}
        sx={{
          "& label.Mui-focused": {
            color: "#707C8B",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },
          },
        }}
      />
      <CustomTable
        rows={filteredRowData.map((camp, index) => ({
          ...camp,
          status: (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={camp.status}
                onChange={(e) => handleStatusChange(index, e.target.value)}
                label="Status"
              >
                <MenuItem value="success">Success</MenuItem>
                <MenuItem value="unsuccessful">Un-Successful</MenuItem>
              </Select>
            </FormControl>
          ),
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
        loading={loading}
      />
    </div>
  );
}
