import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditCandidateModal from './EditCandidateModal';
import ViewCandidateModal from './ViewCandidateModal';
import CustomTable from '../../components/CustomTable/CustomTable';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import './CandidateList.css';
import {
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import HandleErrorToast from '../../helpers/HandleErrorToast';

const tableHeaders = [
  'S.No', 'Name', 'Candidate ID', 'Mobile  No.', 'Aadhar No.', 'Status', 'Action'
];
const tableKeys = ['sNo', 'name', 'candidateId', 'mobileNo', 'aadharNo', 'status', 'action'];

const useStyles = makeStyles(() => ({
  textField: {
    width: '45%',
    marginTop: '45px',
    marginBottom: '39px',
  },
  uploadButton: {
    marginBottom: '20px',
  },
}));

export default function CandidateList() {
  const classes = useStyles();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/candidates`, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataWithSNo = data.map((row, index) => ({ ...row, sNo: index + 1 }));
      setCandidates(dataWithSNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditDialogOpen(true);
  };

  const handleView = (candidate) => {
    setSelectedCandidate(candidate);
    setIsViewDialogOpen(true);
  };

  const handleSave = async (formData) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/candidates/${selectedCandidate._id}`, formData, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
        },
      });
      if (response.status !== 200) {
        throw new Error('Error updating candidate');
      }
      setCandidates((prevCandidates) =>
        prevCandidates.map((candidate) => (candidate._id === selectedCandidate._id ? { ...candidate, ...formData } : candidate))
      );
      HandleSuccessToast('Edited successfully');
      fetchData();
      setIsEditDialogOpen(false);
    } catch (error) {
      console.error('Error updating candidate:', error);
      HandleErrorToast('Something went wrong');
    }
  };

  const handleCloseDialog = () => {
    setIsEditDialogOpen(false);
    setIsViewDialogOpen(false);
    setSelectedCandidate(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCandidates = candidates.length > 0
    ? candidates.filter((row) => {
      const name = row.name ? row.name.toLowerCase() : '';
      const candidateId = row.candidateId ? row.candidateId.toLowerCase() : '';
      return name.includes(searchQuery.toLowerCase()) || candidateId.includes(searchQuery.toLowerCase());
    })
    : [];

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/candidates/uploadFile`, formData, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        HandleSuccessToast('File uploaded successfully');
        fetchData();
      } else {
        throw new Error('Error uploading file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      HandleErrorToast(error.message);
    }
  };

  return (
    <div className='cl-main-container'>
      <TextField
        className={classes.textField}
        variant='outlined'
        placeholder='Search By Name Or ID'
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          style: { borderColor: '#707C8B', background: '#ffffff', borderRadius: '10px', height: '46px', border: '1px solid #707C8B' },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          "& label.Mui-focused": {
            color: "#707C8B",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },
          },
        }}
      />
      <h3 className='cl-heading'>Candidate List</h3>
      <input
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        id="upload-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="upload-file">
        <Button
          variant="contained"
          component="span"
          className={classes.uploadButton}
          startIcon={<UploadFileIcon />}
        >
          Import
        </Button>
      </label>
      <CustomTable
        rows={filteredCandidates.map((candidate) => ({
          ...candidate,
          action: (
            <div>
              <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(candidate)} />
              <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => handleView(candidate)} />
            </div>
          ),
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
        loading={loading}
      />
      <Dialog open={isEditDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Edit Candidate</DialogTitle>
        <DialogContent>
          <EditCandidateModal candidate={selectedCandidate} onSave={handleSave} onCancel={handleCloseDialog} />
        </DialogContent>
      </Dialog>
      <Dialog open={isViewDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Candidate Details</DialogTitle>
        <DialogContent>
          <ViewCandidateModal candidate={selectedCandidate} onClose={handleCloseDialog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
