import {useQuery ,useMutation} from "@tanstack/react-query";
import useAPI from "../services/axios";
import queryKeys from "../services/queryKeys";

const GetCandidateList = async() => {
    const data= await useAPI({ method: 'GET', url: '/candidates' });
    return data;
  };
  
  export const useCandidateList = () => {
    return useQuery({
      queryKey: [queryKeys.GET_CANDIDATE_LIST],
      queryFn: GetCandidateList,
      enabled:true
    });
  };
  
  const EditCandidate = ({ id, data }) => {
    return useAPI({ method: "PUT", url: `/candidates/${id}`, data });
  };
  
  export const useEditCandidate = () => {
    return useMutation({
      mutationFn: (candidate) => EditCandidate(candidate),
    });
  };
  
  const GetCandidateStatusList = async (status) => {
    const data = await useAPI({ method: 'GET', url: `/candidates/${status}` });
    return data;
  };
  
  export const useCandidateStatusList = (status) => {
    return useQuery({
      queryKey: [queryKeys.GET_CANDIDATESTATUS_LIST, status],
      queryFn: () => GetCandidateStatusList(status),
      enabled: !!status
    });
  };