import React,{useEffect,useState} from 'react';
import BasicBars from '../BasicBar/BasicBar';
import { Box } from '@mui/material';
import '../LederDashboard/LeaderDashboard.css';
import MultiHeaderTable from '../MultiHeaderTable/MultiHeaderTable';
import TrainingCenterTable from '../MultiHeaderTable/TrainingCenterTable';
import MeetingReportTable from '../MultiHeaderTable/MeetingReportTable';
import {jwtDecode} from "jwt-decode";

export default function SupportDashboard() {
    const [name, setName] = useState("");
    const [boxNumbers, setBoxNumbers] = useState({
        totalRegistered: 0,
        totalMoved: 0,
        totalUnderReview: 0,
        totalSelected: 0,
        totalRejected: 0,
        totalEnrolled:0
    });
    const [mobRepoBoxNumbers, setMobRepoBoxNumbers] = useState({
        dsm: 0,
        bsm: 0,
        psm:0,
        mobiliser:0
    });
    const [campBoxNumbers, setCampBoxNumbers] = useState({
        totalCamps: 0,
        campsCompleted: 0,
        campsUpcoming: 0,
        campsCancelled:0
    });
    const [meetBoxNumbers, setMeetBoxNumbers] = useState({
        totalMeetings: 0,
        meetingsCompleted: 0,
        meetingsUpcoming: 0,
        meetingsCancelled:0
    });
    const [xAxisDataDate, setXAxisDataDate] = useState([]);
    const [seriesDataDate, setSeriesDataDate] = useState([]);
    const [xAxisDataCamp, setXAxisDataCamp] = useState([]);
    const [seriesDataCamp, setSeriesDataCamp] = useState([]);
    const [centre, setCentre] = useState("");

    
    useEffect(() => {
        const headers = {
            "Authorization": `Bearer ${JSON.stringify({
              token: localStorage.getItem("token")
            })}`,
            "Content-Type": "application/json"
          }
        // Fetch name and training centres
        const token = localStorage.getItem("token");
          if (token) {
              const decodedToken = jwtDecode(token);
              console.log("token",decodedToken);
              const Name = decodedToken.name;
              setName(Name);
              const Centre = decodedToken.centre;
              console.log("centre",Centre);
              setCentre(Centre);
            }
        // Fetch box number
            fetch(`${process.env.REACT_APP_API_URL}/candidates/categorize/status
            `, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setBoxNumbers(data);
            })
            .catch(error => console.error('Error fetching box numbers:', error));

        // Fetch Mobiliser Report  BoxNumbers
        fetch(`${process.env.REACT_APP_API_URL}/mobilisers/categorization`, {headers})
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setMobRepoBoxNumbers(data);
        })
        .catch(error => console.error('Error fetching mobiliser report box numbers:', error));

        // Fetch Camp BoxNumbers
        fetch(`${process.env.REACT_APP_API_URL}/camps/status`, {headers})
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setCampBoxNumbers(data);
        })
        .catch(error => console.error('Error fetching camp box numbers:', error));

        // Fetch Meeting report BoxNumbers
        fetch(`${process.env.REACT_APP_API_URL}/meetings/report`, {headers})
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setMeetBoxNumbers(data);
        })
        .catch(error => console.error('Error fetching camp box numbers:', error));
        
        // Fetch xAxisDataDate and seriesDataDate
        fetch(`${process.env.REACT_APP_API_URL}/candidates/divisionByMonth`, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                setXAxisDataDate(data.xAxisData|| []);
                setSeriesDataDate(data.seriesData|| []);
            })
            .catch(error => console.error('Error fetching date data:', error));

        // Fetch xAxisDataCamp and seriesDataCamp
        fetch(`${process.env.REACT_APP_API_URL}/candidates/divisionByCamp`, {headers})
            .then(response => {
                if (response.status === 204) {
                    console.log('No camps found');
                    return;
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setXAxisDataCamp(data.xAxisData|| []);
                setSeriesDataCamp(data.seriesData|| []);
            })
            .catch(error => console.error('Error fetching camp data:', error));
    }, []);

    return (
        <div className="main-content">
        <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
        <h3 className="l-title">{`Hello ${name} (Support)`}</h3>
        <h3 className="l-title">{`Training Centres: ${centre}`}</h3>
        </div>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Total Registered</div>
                        <div className="l-box-number">{boxNumbers.totalRegistered}</div>
                    </div>
                    <div className="l-box">
                        <div className="box-title">Total Moved</div>
                        <div className="l-box-number">{boxNumbers.totalMoved}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Total Under Process</div>
                        <div className="l-box-number">{boxNumbers.totalUnderReview}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Total Selected</div>
                        <div className="l-box-number">{boxNumbers.totalSelected}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Total Rejected</div>
                        <div className="l-box-number">{boxNumbers.totalRejected}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Total Enrolled</div>
                        <div className="l-box-number">{boxNumbers.totalEnrolled}</div>
                    </div>
                </div>
            </div>
            <h3 className='l-heading'>Mobiliser Report</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">DSM</div>
                        <div className="l-box-number">Total No: {mobRepoBoxNumbers.dsm}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">BSM</div>
                        <div className="l-box-number">Total No: {mobRepoBoxNumbers.bsm}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">PSM</div>
                        <div className="l-box-number">Total No: {mobRepoBoxNumbers.psm}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Mobilisers</div>
                        <div className="l-box-number">Total No: {mobRepoBoxNumbers.mobiliser}</div>
                    </div>
                    
                </div>
            </div>
            <h3 className='l-heading'>Camp Status for Candidates</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Total Camp</div>
                        <div className="l-box-number">{campBoxNumbers.totalCamps}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Camp Complete</div>
                        <div className="l-box-number">{campBoxNumbers.campsCompleted}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Camp Upcoming</div>
                        <div className="l-box-number">{campBoxNumbers.campsUpcoming}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Camp Cancelled</div>
                        <div className="l-box-number">{campBoxNumbers.campsCancelled}</div>
                    </div>
                
                </div>
            </div>
            <h3 className='l-heading'>Campwise Report</h3>
            <MultiHeaderTable/>
            <h3 className='l-heading'>Meeting Report of Mobilisers</h3>
            <div className='l-box-wrapper'>
                <div className="l-box-container">
                    <div className="l-box">
                        <div className="l-box-title">Total</div>
                        <div className="l-box-number">{meetBoxNumbers.totalMeetings}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Complete</div>
                        <div className="l-box-number">{meetBoxNumbers.meetingsCompleted}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Upcoming/ Selected</div>
                        <div className="l-box-number">{meetBoxNumbers.meetingsUpcoming}</div>
                    </div>
                    <div className="l-box">
                        <div className="l-box-title">Cancelled</div>
                        <div className="l-box-number">{meetBoxNumbers.meetingsCancelled}</div>
                    </div>
                
                </div>
            </div>
            
            <h3 className='l-heading'>Meeting wise Report</h3>
            <MeetingReportTable/>
            <h3 className='l-heading'>Training Center wise Requirements</h3>
            <TrainingCenterTable/>
            <Box marginLeft='30px'>
                <BasicBars xText="Month" yText="Registered" xAxisData={xAxisDataDate} seriesData={seriesDataDate}/>
            </Box>
            <h3 className='l-heading'>Camp Success Rate</h3>
            <Box marginLeft='30px'>
            <BasicBars xText="Camp Name" yText="Candidate Registered" xAxisData={xAxisDataCamp} seriesData={seriesDataCamp}/>
            </Box>
        </div>
    );
}