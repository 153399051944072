import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../CampList/CampList.css';
import { TextField } from '@mui/material';
import { useEventsList, useEditEvent, useDeleteEvent } from '../../queries/ManageEventQueries';
// import { useQueryClient } from '@tanstack/react-query';
// import queryKeys from '../../services/queryKeys';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = ['S. No.', 'Title', 'Description', 'Date', 'Time', 'Venue', 'Action'];
const tableKeys = ['sNo', 'title', 'description', 'date', 'time', 'venue', 'action'];

export default function EventList() {
  const [rowData, setRowData] = useState([]);
  const { data: events, error,refetch, isLoading } = useEventsList();
  // const queryClient = useQueryClient(); 
  const editEventMutation = useEditEvent();
  const deleteEventMutation = useDeleteEvent();

  useEffect(() => {
    console.log("Fetched events:", events);
    if (events?.length){
      setRowData(events.map((event, index) => ({
        ...event,
        sNo: index + 1,
        date: event.date.split('T')[0], 
        isEditing: false,
      })));
    }
  }, [events]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  const handleFieldChange = (index, field, value) => {
    setRowData(prevEvents => {
      const updatedEvents = [...prevEvents];
      updatedEvents[index][field] = value;
      return updatedEvents;
    });
  };

  const handleEdit = (index) => {
    setRowData(prevEvents => {
      const updatedEvents = [...prevEvents];
      updatedEvents[index].isEditing = true;
      return updatedEvents;
    });
  };

  const handleSave = (index) => {
    const updatedEvent = rowData[index];
    editEventMutation.mutate(
      { id: updatedEvent._id, data: updatedEvent },
      {
        onSuccess: () => {
          setRowData(prevEvents => {
            const updatedEvents = [...prevEvents];
            updatedEvents[index].isEditing = false;
            HandleSuccessToast('Edited successfully');
            return updatedEvents;
          });
        },
        onError: (error) => {
          console.error('Error updating event:', error);
        }
      }
    );
  };

  const handleCancel = (index) => {
    setRowData(prevEvents => {
      const updatedEvents = [...prevEvents];
      updatedEvents[index].isEditing = false;
      return updatedEvents;
    });
    // queryClient.invalidateQueries([queryKeys.GET_EVENT_LIST]);
    refetch();
  };

  const handleDelete = (id) => {
    deleteEventMutation.mutate(
      id,
      {
        onSuccess: () => {
          // queryClient.invalidateQueries([queryKeys.GET_EVENT_LIST]);
          refetch();
          HandleSuccessToast('Deleted successfully');
        },
        onError: (error) => {
          console.error('Error deleting event:', error);
        }
      }
    );
  };

  return (
    <div className='cl-main-container'>
        <h3 className='cl-heading' style={{marginTop:'25px'}}>Event List</h3>
        <DownloadCSV headers={tableHeaders} data={rowData} filename="events.csv" />
      <CustomTable
        rows={rowData.map((event, index) => ({
          ...event,
          action: event.isEditing ? (
            <div>
              <button onClick={() => handleSave(index)} style={{ backgroundColor: '#112D4E', color: 'white', margin: '5px', padding: '5px', borderRadius: '7px' }}>Save</button>
              <button onClick={() => handleCancel(index)} style={{ backgroundColor: '#112D4E', color: 'white', margin: '5px', padding: '5px', borderRadius: '7px' }}>Cancel</button>
            </div>
          ) : (
            <div>
              <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
              <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDelete(event._id)} />
            </div>
          ),
          title: event.isEditing ? (
            <TextField
              value={event.title}
              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
            />
          ) : event.title,
          description: event.isEditing ? (
            <TextField
              value={event.description}
              onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
            />
          ) : event.description,
          venue: event.isEditing ? (
            <TextField
              value={event.venue}
              onChange={(e) => handleFieldChange(index, 'venue', e.target.value)}
            />
          ) : event.venue
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
    </div>
  );
}
