import React from "react";
import "./App.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppRoutes from "./routing/AppRoutes";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import MuiTheme from "./theme/MuiTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 7,
      enabled: false,
      staleTime: Infinity,
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MuiTheme}>
        <ToastContainer />
        <AppRoutes />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
