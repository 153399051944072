import { createTheme } from "@mui/material";
import { responsiveFontSizes } from "@mui/material";

const MuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#112D4E",
      contrastText: "#FFFFFF",
      light: "#C5FFE3",
    },
    secondary: {
      main: "#B4B4B4",
      contrastText: "#3f4254",
      light: "#ECECEC",
    },
    info: {
      main: "#433DDF",
      contrastText: "#FFFFFF",
      light: "#EFEEFF",
    },
    warning: {
      main: "#ffa700",
      contrastText: "#FFFFFF",
      light: "#fff8dd",
    },
    error: {
      main: "#e81c72",
      contrastText: "#FFFFFF",
      light: "#ffe8f2",
    },
  },
  typography: {
    fontFamily: `Inter, 'sans-serif' !important`,
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#000",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#707C8B",
            },
            "&:hover fieldset": {
              borderColor: "#707C8B",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#707C8B",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "0px",
          marginTop: "2px",
          background: "#FFFF",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          border: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#112D4E",
          color: "#FFFFFF",
          padding: "4px 16px",
          fontFamily: "IBM Plex Sans",
          fontSize: "20px",
          lineHeight: "27.3px",
          fontWeight: 500,
          textTransform: "none",
          borderRadius: "6px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        // Color of the thumb (the draggable circle)
        thumb: {
          color: "#21dd90",
        },
        // Color of the active track (the filled part before the thumb)
        track: {
          color: "#F4F6F8",
          height: 14,
          borderRadius: "4px", // Using the primary main color from the palette
        },
        // Color of the inactive track (the unfilled part after the thumb)
        rail: {
          color: "#F4F6F8",
          height: 14,
          borderRadius: "4px",
        },
        mark: {
          backgroundColor: "#9F9EB2", // Color of the lines
          height: 4, // Height of the lines
          width: 1, // Width of the lines
          marginTop: 12, // Adjust if necessary to align with the slider track
        },
        // Styles for the labels
        markLabel: {
          color: "#000", // Color of the labels
          // Additional label styles...
        },
      },
    },
  },
});

export default responsiveFontSizes(createTheme(MuiTheme));
