import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/login";
import SignUp from "../pages/SignUp/SignUp";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Services from "../pages/Services/Services";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import PrivateRoute from "./PrivateRoute";
import ItTraining from "../IndusX/ItTraining";

const AppRoutes = () => {
  const isUserLogin = Boolean(localStorage.getItem("token"));
  console.log("isUserLogin", isUserLogin);
  return (
    <div>
      <Navbar />
      <Routes>
        {isUserLogin ? (
          <>
            <Route path="/*" element={<PrivateRoute />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/indusX" element={<ItTraining/>} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route exact path="/sign-in" element={<Login />} />
            <Route path="/login-user" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="*" element={<Navigate to="/login-user" />} />
          </>
        )}
      </Routes>
      {!isUserLogin && <Footer />}
    </div>
  );
};

export default AppRoutes;
