import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function BasicBars(props) {
  const { styles, xAxisData, seriesData ,xText,yText} = props;
  return (
    <BarChart
    xAxis={[{ scaleType: "band", data: xAxisData,  label: xText}]}
    yAxis={[{ scaleType: "linear", label: yText }]}
      series={seriesData}
      sx={{
        maxWidth: '446px',
        maxHeight: '199px',
        backgroundColor: "#DBE2EF",
        borderRadius: '2px',
        marginBottom: '50px',
        "& rect": {
          fill: "#3F72AF",
        },
        ...styles
      }}
      width={500}
      height={250}
    />
  );
}
