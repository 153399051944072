import React, { useState } from 'react';
import './Support.css';
import GridViewIcon from '@mui/icons-material/GridView';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListIcon from '@mui/icons-material/List';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CreateCamp from '../../components/CreateCamp/CreateCamp';
import CampList from '../../components/CampList/CampList';
import CandidateList from '../../components/CandidateList/CandidateList';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TocIcon from '@mui/icons-material/Toc';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CallIcon from '@mui/icons-material/Call';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LeaderRegistration from '../../components/LeaderRegistration/LeaderRegistration'
import Register from '../../components/Register/Register'
import MobiliserList from '../../components/MobiliserList/MobiliserList';
import CreateMeeting from '../../components/CreateMeeting/CreateMeeting';
import MeetingList from '../../components/MeetingList/MeetingList';
import TelecallerRegistration from '../../components/TelecallerRegistration/TelecallerRegistration';
import TelecallerList from '../../components/TelecallerList/TelecallerList';
import SupportPayments from '../../components/SupportPayments/SupportPayments';
import SupportDashboard from '../../components/SupportDashboard/SupportDashboard';


const Support = () => {
  const [manageCamp, setManageCamp] = useState(false)
  const [manageCandidate, setManageCandidate] = useState(false)
  const [manageMeetings, setManageMeetings] = useState(false)
  const [manageTelecaller, setManageTelecaller] = useState(false)
  const [selectedPath, setSelectedPath] = useState('dashboard')
  const [manageMobiliser, setManageMobiliser] = useState(false)

  const handleLeftNavPaths= (path) => {
    setSelectedPath(path)
  }

  const handleSelectedPathChange = (path) => {
    setSelectedPath(path);
  };
  
  const handleTabChange = () => {
    if(selectedPath === 'dashboard'){
      return <SupportDashboard />
    }
     else if(selectedPath==='createCamp'){
      return <CreateCamp onFormSubmitSuccess={() => handleSelectedPathChange('campList')} />
    }
    else if(selectedPath ==='campList')
       return <CampList/>
    else if(selectedPath === 'register'){
      return <Register onFormSubmitSuccess={() => handleSelectedPathChange('candidateList')} />
    }
    else if(selectedPath ==='candidateList')
       return <CandidateList/>
    else if(selectedPath ==='registration')
       return <LeaderRegistration onFormSubmitSuccess={() => handleSelectedPathChange('mobiliserList')}/>
    else if (selectedPath === 'mobiliserList'){
      return <MobiliserList/>
    }
    else if(selectedPath === 'createMeeting')
        return <CreateMeeting onFormSubmitSuccess={() => handleSelectedPathChange('meetingList')}/>
    else if (selectedPath === 'meetingList')
       return <MeetingList/>
    else if (selectedPath==='telecallerRegistration')
        return <TelecallerRegistration onFormSubmitSuccess={() => handleSelectedPathChange('telecallerList')}/>
    else if (selectedPath === 'telecallerList')
        return <TelecallerList/>
      else if (selectedPath === 'payments'){
        return <SupportPayments/>
      }
    ///add your condition here...
    
  }

  return (
    <div className="s-app-container">
      <div className="s-content-wrapper">
        <div className="s-sidebar">
          <ul className='s-sidebar-list-container'>
            <li className={selectedPath ==='dashboard' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('dashboard')}>
                <GridViewIcon />
                <p>Dashboard</p>
            </li>
            <li onClick={() => setManageCamp(!manageCamp)}>
                <ManageAccountsIcon/>
                <p>Manage Camp</p>
                {manageCamp ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
              
            </li>
            {manageCamp &&
              <>
              <li className={selectedPath ==='createCamp' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('createCamp')}>
                <NoteAddIcon/>
                <p>Create Camp</p>
              
            </li>
             <li className={selectedPath ==='campList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('campList')}>
                <ListIcon/>
                <p>Camp List</p>
              
            </li>
            </>
            }
            <li onClick={() => setManageCandidate(!manageCandidate)}>
              <ReceiptLongIcon/>
              <p>Candidat Record</p>
              {manageCandidate ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
          </li>
          {manageCandidate &&
              <>
              <li className={selectedPath ==='register' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('register')}>
                <GroupAddIcon/>
                <p>Register</p>
              
            </li> <li className={selectedPath ==='candidateList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('candidateList')}>
                <FormatListNumberedIcon/>
                <p>Candidate List</p>
              
            </li>
            </>
            }
            <li onClick={() => setManageMobiliser(!manageMobiliser)}>
                <ManageHistoryIcon />
                <p>Manage Mobiliser</p>
                {manageMobiliser ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
            </li>
            {manageMobiliser &&
              <>
              <li className={selectedPath ==='registration' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('registration')}>
                <Diversity3Icon/>
                <p>Registration</p>
              
            </li> <li className={selectedPath ==='mobiliserList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('mobiliserList')}>
                <TocIcon/>
                <p>Mobiliser List</p>
              
            </li>
            </>
            }
            <li onClick={() => setManageMeetings(!manageMeetings)}>
                <Diversity2Icon />
                <p>Manage Meetings</p>
                {manageMeetings ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
            </li>
            {manageMeetings &&
              <>
              <li className={selectedPath ==='createMeeting' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('createMeeting')}>
                <ControlPointIcon/>
                <p>Create</p>
              
            </li> <li className={selectedPath ==='meetingList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('meetingList')}>
                <FormatListBulletedIcon/>
                <p>Meeting List</p>
            </li>
            </>
            }
            <li onClick={() => setManageTelecaller(!manageTelecaller)}>
                <CallIcon />
                <p>Manage Telecaller</p>
                {manageTelecaller ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
            </li>
            {manageTelecaller &&
              <>
              <li className={selectedPath ==='telecallerRegistration' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('telecallerRegistration')}>
                <AppRegistrationIcon/>
                <p>Registration</p>
              
            </li> <li className={selectedPath ==='telecallerList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('telecallerList')}>
                <FormatListBulletedIcon/>
                <p>List</p>
              
            </li>
            </>
            }
            <li   className={selectedPath ==='payments' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('payments')}>
                <PaymentIcon />
                <p>Manage Payments</p>
            </li>
            
          </ul>
        </div>
        {handleTabChange()}
      </div>
    </div>
  );
};

export default Support;