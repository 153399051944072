import React, { useState, useEffect } from 'react';
import './MultiHeaderTable.css';

const MeetingReportTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('backend-api');
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="table-container" style={{marginLeft:'4vw'}}>
      
        <table className="multiheader-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Location</th>
              <th>Present</th>
              <th>Registered</th>
              <th>Active</th>
              <th>In-Active</th>
            </tr>
          </thead>
          {loading ? (
        <p>Loading...</p>
      ) : data.length === 0 ? (
        <tbody style={{height:'200px'}}>
          <tr><td colSpan={6} align="center">No data available</td></tr>
        </tbody>
      ) : (
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.location}</td>
                <td>{row.present}</td>
                <td>{row.registered}</td>
                <td>{row.active}</td>
                <td>{row.inactive}</td>
              </tr>
            ))}
          </tbody>
          )}
        </table>
      
    </div>
  );
}

export default MeetingReportTable;
