import React,{useEffect,useState} from 'react'
import axios from 'axios';
import CustomTable from '../../components/CustomTable/CustomTable'
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../../components/CandidateList/CandidateList.css'
import './MeetingList.css'

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';

const tableHeaders = [
  'S.No', 'Date Of Meeting', 'Address', 'Block', 'District', 'Conducted By', 'No.of Participants', 'Status', 'Action'
]
const tableKeys = ['sNo',  'dateOfMeeting','address','block','district','conductedBy','proposedNoOfParticipants','status','action'];

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
  },
}));


export default function MeetingList() {
  const classes = useStyles()
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const headers = {
    "Authorization": `Bearer ${JSON.stringify({
      token:localStorage.getItem("token")
    })}`,
    "Content-Type": "application/json"
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/meetings`, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token: window.localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const formattedData = data.map((row, index) => ({
        ...row,
        dateOfMeeting: row.dateOfMeeting.split('T')[0], 
        sNo: index + 1
      }));
      setCandidates(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (index, field, value) => {
    setCandidates(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index][field] = value;
      return updatedCandidates;
    });
  };

  const handleEdit = (index) => {
    setCandidates(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = true;
      return updatedCandidates;
    });
  };

  const handleSave = async (index) => {
    try {
      const updatedCandidate = candidates[index];
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/meetings/${updatedCandidate._id}`, updatedCandidate, {headers});
      if (response.status !== 200) {
        throw new Error('Error updating candidate');
      }
      setCandidates(prevCandidates => {
        const updatedCandidates = [...prevCandidates];
        // Reset isEditing flag for all fields in the row
        updatedCandidates[index].isEditing = false;
        return updatedCandidates;
      });
    } catch (error) {
      console.error('Error updating candidate:', error);
    }
  };

  const handleCancel = (index) => {
    setCandidates(prevCandidates => {
      const updatedCandidates = [...prevCandidates];
      updatedCandidates[index].isEditing = false;
      // If you want to revert changes made during editing, you can fetch data again
      fetchData();
      return updatedCandidates;
    });
  };

  const handleView = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleCloseModal = () => {
    setSelectedCandidate(null);
  };

  return (
    <div className='cl-main-container ml-heading'>
        <h3 className='cl-heading'>Meeting List</h3>
        <CustomTable
        rows={candidates.map((candidate, index) => ({
          ...candidate,
          status:  candidate.isEditing ? (
            <FormControl variant="outlined" style={{ width: '110px'}}>
              <InputLabel>Status</InputLabel>
              <Select
          value={candidate.status}
          onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
          label="Status"
        >
                <MenuItem value="upcoming">Upcoming</MenuItem>
                <MenuItem value="proposed">Proposed</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
            </FormControl>
          ) : candidate.status,
          dateOfMeeting: candidate.isEditing ? (
            <TextField
              value={candidate.dateOfMeeting}
              onChange={(e) => handleFieldChange(index, 'dateOfMeeting', e.target.value)}
            />
          ) : candidate.dateOfMeeting,
          proposedNoOfParticipants: candidate.isEditing ?(
            <TextField
              value={candidate.proposedNoOfParticipants}
              onChange={(e) => handleFieldChange(index, 'proposedNoOfParticipants', e.target.value)}
            />
          ) : candidate.proposedNoOfParticipants,
          action: candidate.isEditing ? (
            <div>
              <button onClick={() => handleSave(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Save</button>
              <button onClick={() => handleCancel(index)} style={{backgroundColor:'#112D4E',color:'white',margin:'5px',padding:'5px',borderRadius:'7px'}}>Cancel</button>
            </div>
          ) : (
            <div>
              <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
              <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => handleView(candidate)} />
            </div>
          )
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
        loading={loading}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!!selectedCandidate}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 80000,
          },
        }}
      >
        <Fade in={!!selectedCandidate}>
          <div className={classes.modalContent}>
            <h2 id="transition-modal-title" className='modal-content'>Candidate Details</h2>
            {selectedCandidate && (
              <div>
                <p className='modal-details'><strong>DOM: </strong> {selectedCandidate.dateOfMeeting}</p>
                 <p className='modal-details'><strong>Address: </strong> {selectedCandidate.address}</p>
                 <p className='modal-details'><strong>Block: </strong> {selectedCandidate.block}</p>
                 <p className='modal-details'><strong>District: </strong> {selectedCandidate.district}</p>
                 <p className='modal-details'><strong>State: </strong> {selectedCandidate.state}</p>
                <p className='modal-details'><strong>Purpose: </strong> {selectedCandidate.purposeOfMeeting}</p>
                <p className='modal-details'><strong>Conducted By: </strong>{selectedCandidate.conductedBy}</p>
                <p className='modal-details'><strong>No. Of Participants: </strong>{selectedCandidate.proposedNoOfParticipants}</p>
                <p className='modal-details'><strong>Permission From Authority: </strong>{selectedCandidate.permissionFromAuth}</p>
                <p className='modal-details'><strong>Which Authority: </strong>{selectedCandidate.whichAuth}</p>
                <p className='modal-details'><strong>Remark :</strong>{selectedCandidate.remark}</p>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
