import React, { useState } from 'react';
import { Box, Button} from '@mui/material';
import CustomTextField from '../TextField/TextField';
import '../../components/LeaderRegistration/LeaderRegistration.css';
import AutoComplete from '../AutoComplete/AutoComplete';
import { stateOptions } from '../dropdownOptions';
import PhoneNumberField from '../PhoneNumberField';
import HandleSuccessToast from '../../helpers/HandleSuccessToast'
import HandleErrorToast from '../../helpers/HandleErrorToast';

const initialFormData ={
  dateOfRegistration:'',
  nameOfTelecaller: '',
  fatherName: '',
  mobileNo: '',
  emailId:'',
  employeeId:'',
  dateOfJoining:'',
  post:'',
  address: '',
  district: '',
  state: '',
  pin:''
};

export default function TelecallerRegistration  (props) {
  const [formData, setFormData] = useState(initialFormData);
  const [resetValue, setResetValue] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    try {
      const token = localStorage.getItem("token");
      if (!token) {
      HandleErrorToast("Token not found");
      return;
    }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/telecallers`, {
        method: 'POST',
        headers : {
          "Authorization": `Bearer ${JSON.stringify({
            token:localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      
      
      if(response.status===201){
        console.log('Form submitted successfully', response);
        HandleSuccessToast("Registered successfully");
        setFormData(initialFormData);
        setResetValue(!resetValue);
        props.onFormSubmitSuccess();
      }
      else if(response.status===403){
        HandleErrorToast('Session expired, Login again!');
        window.localStorage.clear();
        window.location.reload();
      }
      else {
        const errorText = await response.text();
        HandleErrorToast(errorText || 'Something went wrong');
      }
      
    } catch (error) {
      console.error('Error submitting form:', error);
      HandleErrorToast('Something went wrong');
    }
  };

  const handleChange = (event, teleInfo) => {
    if(teleInfo){
      setFormData({...formData, ['mobileNo']: event})
      return
    }
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    console.log(value);
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.label,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', // Setting to empty string when value is removed
      }));
    }
  };

  return (
    <div className='lr-main-container'>
      <h3 className='lr-heading'>Register</h3>
      <Box className='lr-inputs-container' sx={{ gap: '20px' }}>
        <CustomTextField label='Date of Registration' name="dateOfRegistration" type='date' value={formData.dateOfRegistration} handleChange={handleChange}/>
        <CustomTextField label="Name of Telecaller" name="nameOfTelecaller" value={formData.nameOfTelecaller} handleChange={handleChange} />
        <CustomTextField label="Father's Name" name="fatherName" value={formData.fatherName} handleChange={handleChange}/>
        <PhoneNumberField label='Mobile No.' value={formData.mobileNo} handleChange={handleChange}/>
        <CustomTextField label='Email ID' type="email" name="emailId" value={formData.emailId} handleChange={handleChange} />
        <Box className='date-time-container'>
          <CustomTextField label='Employee ID' styles={{width:'48%'}} name="employeeId" value={formData.employeeId} handleChange={handleChange} />
          <CustomTextField label='Date of Joining' type='date' styles={{width:'48%'}} name="dateOfJoining" value={formData.dateOfJoining} handleChange={handleChange} />
        </Box>
              <CustomTextField label='Post' name='post' value={formData.post} handleChange={handleChange}/>
        <CustomTextField label='Address' name='address' value={formData.address} handleChange={handleChange}/>
        <AutoComplete label='State' styles={{width:'50%', marginBottom:"5px"}} name='state' value={formData.state}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} options={stateOptions}/>
        <Box className='date-time-container'>
              <CustomTextField label='District'styles={{width:'48%'}} name="district" value={formData.district} handleChange={handleChange} />
          <CustomTextField label='PinCode' type='number' styles={{width:'48%'}} name="pin" value={formData.pin} handleChange={handleChange} />
        </Box>
      </Box>
        <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
          <Button variant="contained" className='lr-submit-btn' onClick={handleFormSubmit}>
            Submit
          </Button>
      </Box>
    </div>
  )
}