import React, { useState } from 'react';
import { TextField, Box, Button} from '@mui/material';
import CustomTextField from '../TextField/TextField';
import './LeaderRegistration.css';
import AutoComplete from '../AutoComplete/AutoComplete';
import { postOptions, stateOptions } from '../dropdownOptions';
import HandleSuccessToast from '../../helpers/HandleSuccessToast'
import HandleErrorToast from '../../helpers/HandleErrorToast';
import PhoneNumberField from '../PhoneNumberField'

  const initialFormData ={
    name: '',
    fatherName: '',
    emailId:'',
    mobileNo: '',
    dob: '',
    dateOfRegistration:'',
    aadharNo: '',
    panNo:'',
    address: '',
    // zone:'',
    panchayat:'',
    wardNumber: '',
    block: '',
    district: '',
    state: '',
    post:''
  };
  
export default function LeaderRegistration  (props) {
  const [formData, setFormData] = useState(initialFormData);
  const [resetValue, setResetValue] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    try {
      const token = localStorage.getItem("token");
      if (!token) {
      HandleErrorToast("Token not found");
      return;
    }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/mobilisers`, {
        method: 'POST',
        headers : {
          "Authorization": `Bearer ${JSON.stringify({
            token:localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      
      if(response.status===201){
        console.log('Form submitted successfully', response);
        HandleSuccessToast("Registered successfully");
        setFormData(initialFormData);
        setResetValue(!resetValue);
        // Call the callback function passed from Mobiliser.js
        props.onFormSubmitSuccess();
      }
      else if(response.status===403){
        HandleErrorToast('Session expired, Login again!');
        window.localStorage.clear();
        window.location.reload();
      }
      else {
        HandleErrorToast('Something went wrong');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      HandleErrorToast('Something went wrong');
    }
  };

  const handleChange = (event, teleInfo) => {
    if(teleInfo){
      setFormData({...formData, ['mobileNo']: event})
      return
    }
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    console.log(value);
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.label,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', // Setting to empty string when value is removed
      }));
    }
  };

  return (
    <div className='lr-main-container'>
      <h3 className='lr-heading'>Basic Details</h3>
      <Box className='lr-inputs-container' sx={{ gap: '20px' }}>
        <CustomTextField label='Name' name="name" value={formData.name} handleChange={handleChange}/>
        <CustomTextField label="Father's Name" name="fatherName" value={formData.fatherName} handleChange={handleChange} />
        <CustomTextField label="Email ID" type='email' name="emailId" value={formData.emailId} handleChange={handleChange}/>
        <PhoneNumberField label='Mobile No.' value={formData.mobileNo} handleChange={handleChange}/>
        <Box className='date-time-container'>
          <TextField
            label="DOB"
            id="dob"
            size="small"
            type='date'
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            InputLabelProps={{
              sx: { bgcolor: "#DBE2EF", borderRadius: '5px', fontSize: '15px', lineHeight: '20.25px', padding: '2px 8px 2px 8px', color: '#000' },
            }}
            className='lr-textField2'
            InputProps={{
              style: { borderColor: '#707C8B' }
            }}
            sx={{
              "& label.Mui-focused": {
                color: "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#707C8B",
                },
                "&:hover fieldset": {
                  borderColor: "#707C8B",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#707C8B",
                },
              },
            }}
            focused
          />
          <TextField
            label="Date Of Registration"
            id="age"
            size="small"
            type='date'
            name="dateOfRegistration"
            value={formData.dateOfRegistration}
            onChange={handleChange}
            InputProps={{
              style: { borderColor: '#707C8B' },
            }}
            InputLabelProps={{
              sx: { bgcolor: "#DBE2EF", borderRadius: '5px', fontSize: '15px', lineHeight: '20.25px', padding: '2px 8px 2px 8px', color: '#000' },
            }}
            className='lr-textField2'
            sx={{
              "& label.Mui-focused": {
                color: "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#707C8B",
                },
                "&:hover fieldset": {
                  borderColor: "#707C8B",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#707C8B",
                },
              },
            }}
            focused
          />
        </Box>
        <CustomTextField label='Aadhar No.' 
        maxLength="12" type='number' name='aadharNo' value={formData.aadharNo} handleChange={handleChange}/>
        <CustomTextField label='Pan No.' name='panNo' value={formData.panNo} handleChange={handleChange}/>
      </Box>
      <h3 className='lr-heading'>Location</h3>
      <Box className='lr-inputs-container' sx={{ gap: '20px' }}>
        <CustomTextField label="Address" name="address" multiline={true} value={formData.address} handleChange={handleChange} />
        <CustomTextField label="Panchayat" name="panchayat" value={formData.panchayat} handleChange={handleChange} />
        {/* <CustomTextField label="Zone" name="zone" value={formData.zone} handleChange={handleChange} /> */}
        <Box className='date-time-container'>
          <TextField
            label='Ward Number'
            id="dob"
            size="small"
            type='number' name='wardNumber' value={formData.wardNumber} onChange={handleChange}
            InputLabelProps={{
              sx: { bgcolor: "#DBE2EF", borderRadius: '5px', fontSize: '15px', lineHeight: '20.25px', padding: '2px 8px 2px 8px', color: '#000' },
            }}
            className='lr-textField2'
            InputProps={{
              style: { borderColor: '#707C8B' }
            }}
            sx={{
              "& label.Mui-focused": {
                color: "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#707C8B",
                },
                "&:hover fieldset": {
                  borderColor: "#707C8B",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#707C8B",
                },
              },
            }}
            focused
          />
          <TextField
            label='Block'  
            id="age"
            size="small"
            name='block' value={formData.block} onChange={handleChange}
            InputProps={{
              style: { borderColor: '#707C8B' },
            }}
            InputLabelProps={{
              sx: { bgcolor: "#DBE2EF", borderRadius: '5px', fontSize: '15px', lineHeight: '20.25px', padding: '2px 8px 2px 8px', color: '#000' },
            }}
            className='lr-textField2'
            sx={{
              "& label.Mui-focused": {
                color: "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#707C8B",
                },
                "&:hover fieldset": {
                  borderColor: "#707C8B",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#707C8B",
                },
              },
            }}
            focused
          />
        </Box>
        <Box className='lr-date-time-container'>
        <CustomTextField label='District'styles={{width:'48%'}} name="district" value={formData.district} handleChange={handleChange} />
           <AutoComplete label='State' styles={{width:'48%'}} name='state' value={formData.state}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} options={stateOptions}/>
        </Box>
        <AutoComplete label='Post' styles={{width:'50%'}} name='post' value={formData.post}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} options={postOptions}/>    
        </Box>
        <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
          <Button variant="contained" className='lr-submit-btn' onClick={handleFormSubmit}>
            Submit
          </Button>
      </Box>
    </div>
  )
}