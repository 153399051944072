import React from 'react';
import { Button } from '@mui/material';

const DownloadCSV = ({ headers, data, filename = 'data.csv' }) => {
  const handleDownload = () => {
    const csvRows = [];
    csvRows.push(headers.join(','));
    data.forEach(row => {
      const values = headers.map(key => row[key]);
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Button variant="contained" style={{ marginBottom: '25px', backgroundColor: '#112D4E', color: 'white' }} onClick={handleDownload}>
      Download CSV
    </Button>
  );
};

export default DownloadCSV;
