import React from 'react'
import { useNavigate } from 'react-router-dom';

function NoticeBoard() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/indusX');
      };
    
  return (
<div style={{backgroundColor:'#4054B2',height:'12vh',margin:'5px',display:'flex',justifyContent:'center',alignContent:'center'}}>
        <button onClick={handleClick} style={{fontSize:'23px',fontWeight:'700',color:'white'}}>Build your career in IT fields free of cost, Click for more information</button>
    </div>
  )
}

export default NoticeBoard
