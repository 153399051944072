import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../components/CandidateList/CandidateList.css';
import '../MeetingList/MeetingList.css';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Backdrop,
  Fade
} from '@mui/material';
import { useMobilisersList, useEditMobiliser, useDeleteMobiliser } from '../../queries/ManageMobiliserQueries';
// import { useQueryClient } from '@tanstack/react-query';
// import queryKeys from '../../services/queryKeys';
import DownloadCSV from '../DownloadCSV';

const tableHeaders = [
  'S.No', 'Name', "Father's Name", 'Aadhar No.', 'Mobile No.', 'Address', 'District', 'Status', 'Action'
];
const tableKeys = ['sNo', 'name', 'fatherName', 'aadharNo', 'mobileNo', 'address', 'district', 'status', 'action'];

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
  },
}));

export default function ViewMobiliser() {
  const classes = useStyles();
  const [selectedMobiliser, setSelectedMobiliser] = useState(null);
  const [rowData, setRowData] = useState([]);

  const { data: mobilisers, error,refetch ,isLoading} = useMobilisersList();
  const editMobiliserMutation = useEditMobiliser();
  const deleteMobiliserMutation = useDeleteMobiliser();

  // const queryClient = useQueryClient(); 

  useEffect(() => {
    console.log("Fetched mobilisers:", mobilisers);
    if (mobilisers?.length){
      setRowData(mobilisers.map((mobiliser, index) => ({
        ...mobiliser,
        sNo: index + 1,
        isEditing: false,
      })));
    }
  }, [mobilisers]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading events: {error.message}</div>;

  const handleFieldChange = (index, field, value) => {
    setRowData(prevMobilisers => {
      const updatedMobilisers = [...prevMobilisers];
      updatedMobilisers[index][field] = value;
      return updatedMobilisers;
    });
  };

  const handleEdit = (index) => {
    setRowData(prevMobilisers => {
      const updatedMobilisers = [...prevMobilisers];
      updatedMobilisers[index].isEditing = true;
      return updatedMobilisers;
    });
  };

  const handleSave = (index) => {
    const updatedMobiliser = rowData[index];
    editMobiliserMutation.mutate(
      { id: updatedMobiliser._id, data: updatedMobiliser },
      {
        onSuccess: () => {
          setRowData(prevMobilisers => {
            const updatedMobilisers = [...prevMobilisers];
            updatedMobilisers[index].isEditing = false;
            HandleSuccessToast('Mobiliser edited successfully');
            return updatedMobilisers;
          });
        },
        onError: (error) => {
          console.error('Error updating mobiliser:', error);
        }
      }
    );
  };

  const handleCancel = (index) => {
    setRowData(prevMobilisers => {
      const updatedMobilisers = [...prevMobilisers];
      updatedMobilisers[index].isEditing = false;
      return updatedMobilisers;
    });
    // queryClient.invalidateQueries([queryKeys.GET_MOBILISER_LIST]);
    refetch();
  };

  const handleView = (mobiliser) => {
    setSelectedMobiliser(mobiliser);
  };

  const handleCloseModal = () => {
    setSelectedMobiliser(null);
  };

  const handleDelete = (id) => {
    deleteMobiliserMutation.mutate(
      id,
      {
        onSuccess: () => {
          // queryClient.invalidateQueries([queryKeys.GET_MOBILISER_LIST]);
         refetch();
         HandleSuccessToast('Mobiliser deleted successfully');
        },
        onError: (error) => {
          console.error('Error deleting mobiliser:', error);
        }
      }
    );
  };

  return (
    <div className='cl-main-container ml-heading'>
      <h3 className='cl-heading'>Mobiliser List</h3>
      <DownloadCSV headers={tableHeaders} data={rowData} filename="mobilisers.csv" />
      <CustomTable
        rows={rowData.map((mobiliser, index) => ({
          ...mobiliser,
          status: mobiliser.isEditing ? (
            <FormControl variant="outlined" style={{ width: '110px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={mobiliser.status}
                onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
                label="Status"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="terminated">Terminated</MenuItem>
                <MenuItem value="in-active">In-Active</MenuItem>
              </Select>
            </FormControl>
          ) : mobiliser.status,
          aadharNo: mobiliser.isEditing ? (
            <TextField
              value={mobiliser.aadharNo}
              onChange={(e) => handleFieldChange(index, 'aadharNo', e.target.value)}
            />
          ) : mobiliser.aadharNo,
          mobileNo: mobiliser.isEditing ? (
            <TextField
              value={mobiliser.mobileNo}
              onChange={(e) => handleFieldChange(index, 'mobileNo', e.target.value)}
            />
          ) : mobiliser.mobileNo,
          action: mobiliser.isEditing ? (
            <div>
              <button onClick={() => handleSave(index)} style={{ backgroundColor: '#112D4E', color: 'white', margin: '5px', padding: '5px', borderRadius: '7px' }}>Save</button>
              <button onClick={() => handleCancel(index)} style={{ backgroundColor: '#112D4E', color: 'white', margin: '5px', padding: '5px', borderRadius: '7px' }}>Cancel</button>
            </div>
          ) : (
            <div>
              <EditIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(index)} />
              <VisibilityIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleView(mobiliser)} />
              <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDelete(mobiliser._id)} />
            </div>
          )
        }))}
        tableHeaders={tableHeaders}
        tableKeys={tableKeys}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!!selectedMobiliser}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 80000,
          },
        }}
      >
        <Fade in={!!selectedMobiliser}>
          <div className={classes.modalContent}>
            <h2 id="transition-modal-title" className='modal-content'>Mobiliser Details</h2>
            {selectedMobiliser && (
              <div>
                <p className='modal-details'><strong>Name: </strong> {selectedMobiliser.name}</p>
                <p className='modal-details'><strong>Father's Name: </strong> {selectedMobiliser.fatherName}</p>
                <p className='modal-details'><strong>Email ID: </strong> {selectedMobiliser.emailId}</p>
                <p className='modal-details'><strong>Aadhar No.: </strong> {selectedMobiliser.aadharNo}</p>
                <p className='modal-details'><strong>Pan No.: </strong> {selectedMobiliser.panNo}</p>
                <p className='modal-details'><strong>Mobile No.: </strong> {selectedMobiliser.mobileNo}</p>
                <p className='modal-details'><strong>Date of birth: </strong> {selectedMobiliser.dob?.split('T')[0] ?? 'N/A'}</p>
                <p className='modal-details'><strong>Date of Registration: </strong> {selectedMobiliser.dateOfRegistration?.split('T')[0] ?? 'N/A'}</p>
                <p className='modal-details'><strong>Post: </strong> {selectedMobiliser.post}</p>
                <p className='modal-details'><strong>Address: </strong> {selectedMobiliser.address}</p>
                <p className='modal-details'><strong>District: </strong> {selectedMobiliser.district}</p>
                <p className='modal-details'><strong>Panchayat: </strong> {selectedMobiliser.panchayat}</p>
                <p className='modal-details'><strong>Ward Number: </strong> {selectedMobiliser.wardNumber}</p>
                <p className='modal-details'><strong>Block: </strong> {selectedMobiliser.block}</p>
                <p className='modal-details'><strong>State: </strong> {selectedMobiliser.state}</p>
                <p className='modal-details'><strong>Aadhar Image: </strong>{selectedMobiliser.aadharPhoto}</p>
                <p className='modal-details'><strong>Photograph: </strong>{selectedMobiliser.photo}</p>
                <p className='modal-details'><strong>Pan Card: </strong>{selectedMobiliser.panPhoto}</p>
                <p className='modal-details'><strong>Bank Passbook:</strong>{selectedMobiliser.bankAccountPhoto}</p>
                <p className='modal-details'><strong>Status: </strong>{selectedMobiliser.status}</p>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}