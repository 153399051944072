import React, { useEffect, useState } from "react";
import BasicBars from "../BasicBar/BasicBar";
import { Box } from "@mui/material";
import "./LeaderDashboard.css";
import MultiHeaderTable from "../MultiHeaderTable/MultiHeaderTable";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

export default function LeaderDashboard() {
  const [name, setName] = useState("");
  const [areaType,setAreaType] = useState("");
  const [area,setArea] = useState("");
  const [boxNumbers, setBoxNumbers] = useState({
    bsm: 0,
    psm: 0,
    mobiliser:0,
  });
  const [xAxisDataDate, setXAxisDataDate] = useState([]);
  const [seriesDataDate, setSeriesDataDate] = useState([]);
  const [xAxisDataCamp, setXAxisDataCamp] = useState([]);
  const [seriesDataCamp, setSeriesDataCamp] = useState([]);
  const [xAxisDataBsm, setXAxisDataBsm] = useState([]);
  const [seriesDataBsm, setSeriesDataBsm] = useState([]);
  const [xAxisDataPsm, setXAxisDataPsm] = useState([]);
  const [seriesDataPsm, setSeriesDataPsm] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${JSON.stringify({
        token: localStorage.getItem("token"),
      })}`,
      "Content-Type": "application/json",
    };
    // Fetch name
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const Name = decodedToken.name;
      setName(Name);
      const leaderType = decodedToken.post;
      if (leaderType === 'DSM'){
        setAreaType("District");
        setArea(decodedToken.district) ;
      }
      else if(leaderType === 'PSM'){
        setAreaType("Panchayat");
        setArea(decodedToken.panchayat) ;
      }
      else{
        setAreaType("Block");
        setArea(decodedToken.block) ;
      }
    }
    
    // Fetch box numbers
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/mobilisers/categorization`,
          { headers }
        );
        if (response.status === 200) {
          setBoxNumbers(response.data);
        } else if (response.status === 204) {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching box numbers:", error);
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/candidates/divisionByMonth`,
          { headers }
        );
        if (response.status === 200) {
          setXAxisDataDate(response.data.xAxisData|| []);
          console.log(response?.data.length);
          setSeriesDataDate(response.data.seriesData|| []);
        } else if (response.status === 204) {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching candidates data:", error);
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/candidates/divisionByCamp`,
          { headers }
        );
        if (response.status === 200) {
          setXAxisDataCamp(response.data.xAxisData|| []);
          setSeriesDataCamp(response.data.seriesData|| []);
        } else if (response.status === 204) {
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching camp data:", error);
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/mobilisers/report/BSM`,
          { headers }
        );
        if (response.status === 200) {
          setXAxisDataBsm(response.data.xAxisData|| []);
          setSeriesDataBsm(response.data.seriesData|| []);
        } else if (response.status === 204) {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error("Error fetching date data:", error);
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/mobilisers/report/PSM`,
          { headers }
        );
        if (response.status === 200) {
          setXAxisDataPsm(response.data.xAxisData|| []);
          setSeriesDataPsm(response.data.seriesData|| []);
        } else if (response.status === 204) {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error("Error fetching date data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="main-content">
      <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
      <h3 className="l-title">{`Hello ${name} (Leader)`}</h3>
      <h3 className="l-title">{`${areaType}: ${area}`}</h3>
      </div>
      <h3 className="l-heading">Mobiliser Report</h3>
      <div className="l-box-wrapper">
        <div className="l-box-container">
          <div className="l-box">
            <div className="box-title">BSM</div>
            <div className="l-box-number">{boxNumbers.bsm}</div>
          </div>
          <div className="l-box">
            <div className="l-box-title">PSM</div>
            <div className="l-box-number">{boxNumbers.psm}</div>
          </div>
          <div className="l-box">
            <div className="l-box-title">Mobilisers</div>
            <div className="l-box-number">{boxNumbers.mobiliser}</div>
          </div>
        </div>
      </div>
      <h3 className="l-heading">Campwise Report</h3>
      <MultiHeaderTable/>
      <BasicBars
        xText="Month"
        yText="Candidate Registered"
        xAxisData={xAxisDataDate}
        seriesData={seriesDataDate}
        styles={{ marginLeft: "67px" }}
      />
      <h3 className="l-heading">Camp Success Rate</h3>
      <BasicBars
        xText="Camp Name"
        yText="Candidate Registered"
        xAxisData={xAxisDataCamp}
        seriesData={seriesDataCamp}
        styles={{ marginLeft: "67px" }}
      />
      <h3 className="l-heading">Mobiliser Performance</h3>
      <Box className="bard-container">
        <BasicBars
          xText="Name of BSM"
          yText="Candidate Registered"
          xAxisData={xAxisDataBsm}
          seriesData={seriesDataBsm}
        />
        <BasicBars
          xText="Name of PSM"
          yText="Candidate Registered"
          xAxisData={xAxisDataPsm}
          seriesData={seriesDataPsm}
        />
      </Box>
    </div>
  );
}
