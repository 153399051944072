const queryKeys = {
    GET_CANDIDATE_BOXNUMBER:"GET_CANDIDATE_BOXNUMBER",
    GET_MOBILISER_STATUS:"GET_MOBILISER_STATUS",
    GET_CENTERWISE_TABLEDATA:"GET_CENTERWISE_TABLEDATA",
    GET_YEARWISE_DATA:"GET_YEARWISE_DATA",
    GET_JOBROLEWISE_DATA:"GET_JOBROLEWISE_DATA",
    GET_MOBILISER_LIST:"GET_MOBILISER_LIST",
    GET_CANDIDATE_LIST:"GET_CANDIDATE_LIST",
    GET_CANDIDATESTATUS_LIST:"GET_CANDIDATESTATUS_LIST",
    GET_EVENT_LIST:"GET_EVENT_LIST",
    GET_PAYMENT_LIST:"GET_PAYMENT_LIST",
    GET_PAYMENT_STATUS_LIST:"GET_PAYMENT_STATUS_LIST",
    GET_USER_DETAILS: "GET_USER_DETAILS",
};

export default queryKeys;