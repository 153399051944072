import React,{useState} from 'react'
import {TextField, Box, Button} from '@mui/material'
import CustomTextField from '../TextField/TextField'
import HandleSuccessToast from '../../helpers/HandleSuccessToast'
import HandleErrorToast from '../../helpers/HandleErrorToast'


import "./CreateCamp.css"

export default function CreateCamp(props) {
  const initialFormData = {
    schemeName: '',
    sponsoredAgency: '',
    eventTitle: '',
    date: '',
    time: '',
    address: '',
    // zone:'',
    district: '',
    block: '',
    panchayat: '',
    landmark: '',
    guestName: '',
    guestDetails: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [resetValue, setResetValue] = useState(false);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    try {
    const token = localStorage.getItem("token");
    if (!token) {
      HandleErrorToast("Token not found");
      return;
    }
      const headers = {
        "Authorization": `Bearer ${JSON.stringify({
          token:localStorage.getItem("token")
        })}`,
        "Content-Type": "application/json"
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/camps`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formData)
      });
      
      if(response.status===201){
        console.log('Form submitted successfully', response);
        HandleSuccessToast("Camp created successfully");
        setFormData(initialFormData);
        setResetValue(!resetValue);
        // Call the callback function passed from Mobiliser.js
        props.onFormSubmitSuccess();
      }
      else if(response.status===403){
        HandleErrorToast('Session expired, Login again!');
        window.localStorage.clear();
        window.location.reload();
      }
      else {
        const errorText = await response.text();
        HandleErrorToast(errorText || 'Something went wrong');
      }
      
    } catch (error) {
      HandleErrorToast('Something went wrong');
      console.error('Error submitting form:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };


  return (
    <form className='cc-main-container' onSubmit={handleSubmit}>
      <h3 className='cc-heading'>Basic Details</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
        <CustomTextField label='Scheme Name' name='schemeName' value={formData.schemeName} handleChange={handleChange} />
        <CustomTextField label='Sponsored Agency' name='sponsoredAgency' value={formData.sponsoredAgency} handleChange={handleChange} />
        <CustomTextField label='Event Title' name='eventTitle' value={formData.eventTitle} handleChange={handleChange} />
          <Box className='date-time-container'>
          <TextField
          label="Date"
          name='date'
          value={formData.date}
          onChange={handleChange}
          id="filled"
          size="small"
          type='date'
          InputLabelProps={{
            sx: { bgcolor: "#DBE2EF", borderRadius:'5px', fontSize:'15px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
          }}
          className='textField2'
          InputProps={{
            style:{borderColor:'#707C8B'}
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#707C8B",
              },
              "&:hover fieldset": {
                borderColor: "#707C8B",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#707C8B",
              },
            },
          }}
          focused
          />
          <TextField
          label="Time"
          name='time'
          value={formData.time}
          onChange={handleChange}
          id="filled"
          type='time'
          size="small"
          InputLabelProps={{
            sx: { bgcolor: "#DBE2EF", borderRadius:'5px', fontSize:'15px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
          }}
          className='textField2'
          InputProps={{
            style:{borderColor:'#707C8B'}
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#707C8B",
              },
              "&:hover fieldset": {
                borderColor: "#707C8B",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#707C8B",
              },
            },
          }}
          focused
          />
          </Box>
      </Box>
      <h3 className='cc-heading'>Venue</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
        <CustomTextField  label='Address' multiline={true} name='address' value={formData.address} handleChange={handleChange}/>
        {/* <CustomTextField label="Zone" name="zone" value={formData.zone} handleChange={handleChange} /> */}
            <CustomTextField label='Block' name='block' value={formData.block}
              handleChange={handleChange}/>
                <CustomTextField  label='District' name='district' value={formData.district} handleChange={handleChange}/>
          <Box className='date-time-container'>
           <TextField
          label="Panchayat"
          name='panchayat'
          value={formData.panchayat}
          onChange={handleChange}
          id="filled"
          size="small"
          InputLabelProps={{
            sx: { bgcolor: "#DBE2EF", borderRadius:'5px', fontSize:'15px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
          }}
          className='textField2'
          InputProps={{
            style:{borderColor:'#707C8B'}
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#707C8B",
              },
              "&:hover fieldset": {
                borderColor: "#707C8B",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#707C8B",
              },
            },
          }}
          focused
          />
           <TextField
          label="Landmark"
          name='landmark'
          value={formData.landmark}
          onChange={handleChange}
          id="filled"
          size="small"
          InputLabelProps={{
            sx: { bgcolor: "#DBE2EF", borderRadius:'5px', fontSize:'15px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
          }}
          className='textField2'
          InputProps={{
            style:{borderColor:'#707C8B'}
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#707C8B",
              },
              "&:hover fieldset": {
                borderColor: "#707C8B",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#707C8B",
              },
            },
          }}
          focused
          />
          </Box>
      </Box>
      <h3 className='cc-heading'>Guest</h3>
      <Box className='inputs-container' sx={{gap:'20px'}}>
      <CustomTextField label='Name' name='guestName' value={formData.guestName} handleChange={handleChange} />
      <CustomTextField label='Details' name='guestDetails' value={formData.guestDetails} handleChange={handleChange}/>
      </Box>
      <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
          <Button variant="contained" className='submit-btn' type='submit'>
            Submit
          </Button>
      </Box>
    </form>
  )
}