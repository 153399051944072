import React from 'react'
import { MuiTelInput } from 'mui-tel-input'


export default function PhoneNumberField(props) {

     
  return (
       <MuiTelInput label={props.label} value={props.value } defaultCountry="IN" onChange={(value, info) => props.handleChange(value, info)}
          InputProps={{
            style: { borderColor: '#707C8B' },
          }}
          InputLabelProps={{
            sx: { bgcolor: "#DBE2EF", borderRadius: '5px', fontSize: '15px', lineHeight: '20.25px', padding: '2px 8px 2px 8px', color: '#000' },
          }}
          className='r-textField tele-field'
          sx={{
            "& label.Mui-focused": {
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              height:'2.6rem',
              "& fieldset": {
                borderColor: "#707C8B",
              },
              "&:hover fieldset": {
                borderColor: "#707C8B",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#707C8B",
              },
            },
          }}
          focused
    />
  )
}
