import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {Box} from '@mui/material'
import './Footer.css'


export default function Footer() {
  return (
    <footer className="footer">
        <Box className='boxs-container'>
           <Box className='sub-box'>
                <img src='https://sgrsgroup.com/wp-content/uploads/2023/06/1200x300px-2.png' className='sgrs-logo-image' alt='logo'/>
                <ul>
                    <li className='list-item'>
                     <LocalPhoneOutlinedIcon sx={{color:"#ffffff", marginRight:'5px'}}/>
                     0651 - 2332878
                    </li>
                    <li className='list-item'>
                     <LocationOnOutlinedIcon sx={{color:"#ffffff", marginRight:'5px'}}/>
                     3rd floor, Sri Mohan Building, Sita Compound 5, Near Over Bridge, Main Road, Ranchi - 834001 (Jharkhand)
                    </li>
                    <li className='list-item'>
                     <MarkEmailReadOutlinedIcon sx={{color:"#ffffff", marginRight:'5px'}}/>
                     info@sgrsgroup.com
                    </li>
                </ul>
           </Box>
        </Box>
    {/* <div className="footer-container">
        <div className="footer-section">
            <h3>About Us</h3>
            <p>Learn more about our company and mission.</p>
            <a href="/about">Read More<ArrowOutwardIcon sx={{color:'blue', fontSize:'16px'}}/></a>
        </div>
        <div className="footer-section">
            <h3>Contact Us</h3>
            <p>Have questions? Contact our support team.</p>
            <a href="/contact">Get in Touch<ArrowOutwardIcon sx={{color:'blue', fontSize:'16px'}}/></a>
        </div>
        <div className="footer-section">
            <h3>Follow Us</h3>
            <p>Stay connected with us on social media.</p>
            <ul className="social-links">
                <li><a href="#" className="icon-facebook"><FacebookIcon/></a></li>
                <li><a href="#" className="icon-twitter"><XIcon/></a></li>
                <li><a href="#" className="icon-instagram"><InstagramIcon/></a></li>
            </ul>
        </div>
    </div>
    <div className="footer-bottom">
        <p>&copy; Copyright 2024 SGRS Academics Pvt.Ltd | Privacy Policy | Leagl | About Cookies</p>
    </div> */}

    <ul className="social-links">
                <li><a href="#" className="icon-facebook"><FacebookIcon/></a></li>
                <li><a href="#" className="icon-twitter"><XIcon/></a></li>
                <li><a href="#" className="icon-instagram"><InstagramIcon/></a></li>
                <li><a href="#" className="icon-instagram"><YouTubeIcon/></a></li>
    </ul>
    <div className="footer-bottom">
        <p>&copy; Copyright 2024 SGRS Academics Pvt.Ltd | Privacy Policy | Leagl | About Cookies</p>
    </div>
</footer>

  )
}

