import React, { useState, useEffect } from 'react';
import './MultiHeaderTable.css';

const MultiHeaderTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/camps/report`, {
        headers: {
          "Authorization": `Bearer ${JSON.stringify({
            token:localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const jsonData = await response.json();
      console.log(data);
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  

  return (
    <div className="table-container" style={{marginLeft:'4vw',marginBottom:'50px'}}>
      <table className="multiheader-table">
        <thead>
          <tr>
            <th rowSpan={2}>S.No.</th>
            <th rowSpan={2}>Venue</th>
            <th colSpan={2}>Registered</th>
            <th colSpan={2}>Moved</th>
            <th colSpan={2}>Selected</th>
            <th colSpan={2}>Rejected</th>
            <th colSpan={2}>Enrolled</th>
          </tr>
          <tr>
            <th>Boy</th>
            <th>Girl</th>
            <th>Boy</th>
            <th>Girl</th>
            <th>Boy</th>
            <th>Girl</th>
            <th>Boy</th>
            <th>Girl</th>
            <th>Boy</th>
            <th>Girl</th>
          </tr>
        </thead>
        <tbody  style={{height:'200px'}}>
          {loading ? (
            <tr><td colSpan={12} align="center">Loading...</td></tr>
          ) : data.length === 0 ? (
            <tr><td colSpan={12} align="center">No data available</td></tr>
          ) : (
            data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.venue}</td>
                <td>{row.registered[0]}</td>
                <td>{row.registered[1]}</td>
                <td>{row.moved[0]}</td>
                <td>{row.moved[1]}</td>
                <td>{row.selected[0]}</td>
                <td>{row.selected[1]}</td>
                <td>{row.rejected[0]}</td>
                <td>{row.rejected[1]}</td>
                <td>{row.enrolled[0]}</td>
                <td>{row.enrolled[1]}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MultiHeaderTable;
