import { useMutation, useQuery } from "@tanstack/react-query";
import useAPI from "../services/axios";
import queryKeys from "../services/queryKeys";

const AddEvents = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (key === 'photos') {
      data[key].forEach((file, index) => {
        formData.append(`photos`, file);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return useAPI({ 
    method: "POST", 
    url: "/events", 
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const useAddEvents = () => {
  return useMutation({
    mutationFn: (data) => AddEvents(data),
  });
};

const GetEventList = async () => {
  const data = await useAPI({ method: 'GET', url: '/events' });
  return data;
};

export const useEventsList = () => {
  return useQuery({
    queryKey: [queryKeys.GET_EVENT_LIST],
    queryFn: GetEventList,
    enabled: true,
  });
};

const EditEvent = async (data) => {
  const formData = new FormData();
  Object.keys(data.data).forEach(key => {
    if (key === 'photos') {
      data.data[key].forEach((file, index) => {
        formData.append(`photos`, file); 
      });
    } else {
      formData.append(key, data.data[key]);
    }
  });

  return useAPI({ 
    method: "PUT", 
    url: `/events/${data.id}`, 
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const useEditEvent = () => {
  return useMutation({
    mutationFn: (data) => EditEvent(data),
  });
};

const DeleteEvent = (id) => {
  return useAPI({ method: "DELETE", url: `/events/${id}` });
};

export const useDeleteEvent = () => {
  return useMutation({
    mutationFn: (id) => DeleteEvent(id),
  });
};
