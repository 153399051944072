import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CustomTextField from '../TextField/TextField';
import '../Register/Register.css'
import AutoComplete from '../AutoComplete/AutoComplete';
import { authorityOptions, stateOptions } from '../dropdownOptions';
import HandleSuccessToast from '../../helpers/HandleSuccessToast';
import HandleErrorToast from '../../helpers/HandleErrorToast';

export default function CreateMeeting(props) {

  const initialFormData = {
    dateOfMeeting:'',
    address: '',
    block: '',
    district: '',
    state: '',
    purposeOfMeeting:'',
    conductedBy:'',
    proposedNoOfParticipants:'',
    permissionFromAuth:'',
    whichAuth:'',
    remark:''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [resetValue, setResetValue] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    try {
      const token = localStorage.getItem("token");
      if (!token) {
      HandleErrorToast("Token not found");
      return;
    }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/meetings`, {
        method: 'POST',
        headers : {
          "Authorization": `Bearer ${JSON.stringify({
            token:localStorage.getItem("token")
          })}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      
      if(response.status===201){
        console.log('Form submitted successfully', response);
        HandleSuccessToast("Meeting created successfully");
        setFormData(initialFormData);
        setResetValue(!resetValue);
        props.onFormSubmitSuccess();
      }
      else if(response.status===403){
        HandleErrorToast('Session expired, Login again!');
        window.localStorage.clear();
        window.location.reload();
      }
      else {
        const errorText = await response.text();
        HandleErrorToast(errorText || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      HandleErrorToast('Something went wrong');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    console.log(value);
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.label,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', 
      }));
    }
  };
  
  return (
    <div className='r-main-container'>
      <h3 className='r-heading'>Schedule Meeting</h3>
      <Box className='r-inputs-container' sx={{ gap: '20px' }}>
        <CustomTextField label='Date Of Meeting' type='date' name="dateOfMeeting" value={formData.dateOfMeeting} handleChange={handleChange}/>
        <CustomTextField label="Address" name="address" value={formData.address} handleChange={handleChange} />
        <Box sx={{display:'flex', justifyContent:'space-between', width:'50%', height:"42px"}}>
            <CustomTextField label='Block' name="block" value={formData.block} handleChange={handleChange} styles={{width:'48%'}}/>
            <CustomTextField label='District'styles={{width:'48%'}} name="district" value={formData.district} handleChange={handleChange} />
        </Box>
        <AutoComplete label="State" name='state' value={formData.state} options={stateOptions}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} styles={{width:"50%", marginBottom:'5px'}} />
        <CustomTextField label='Purpose Of Meeting' multiline name="purposeOfMeeting" value={formData.purposeOfMeeting} handleChange={handleChange} />
        <CustomTextField label='Conducted By' name="conductedBy" value={formData.conductedBy}   handleChange={handleChange} />
        <CustomTextField label='Proposed No.of Participants' type='number' name='proposedNoOfParticipants' value={formData.proposedNoOfParticipants} handleChange={handleChange}/>
        <Box sx={{display:'flex', justifyContent:'center', width:'50%', height:"42px"}}>
            <CustomTextField label='Permission From Authority (Y/N)' styles={{width:'50%'}} name="permissionFromAuth" value={formData.permissionFromAuth}   handleChange={handleChange}/> 
            <AutoComplete label='Which Authority?' styles={{width:"50%"}} name='whichAuth' value={formData.whichAuth}
              handleAutocompleteChange={handleAutocompleteChange} resetValue={resetValue} options={authorityOptions}/>
        </Box>
        <CustomTextField label='Remark'   multiline  name='remark' value={formData.remark} handleChange={handleChange}/>
      </Box>
        <Box sx={{width:'100%', marginTop:'34px', marginBottom:'54px',textAlign:'center'}}>
          <Button variant="contained" className='r-submit-btn' onClick={handleFormSubmit}>
            Submit
          </Button>
      </Box>
    </div>
  )
}