import React, { useState } from 'react';
import './MobiliserHead.css';
import GridViewIcon from '@mui/icons-material/GridView';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListIcon from '@mui/icons-material/List';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CancelIcon from '@mui/icons-material/Cancel';
import SchoolIcon from '@mui/icons-material/School';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TocIcon from '@mui/icons-material/Toc';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HeadDashboard from '../../components/HeadDashboard/HeadDashboard';
import AddMobiliser from '../../components/ManageMobiliser/AddMobiliser';
import ViewMobiliser from '../../components/ManageMobiliser/ViewMobiliser';
import MobSwWise from '../../components/ManageCandidate/MobSwWise';
import Selected from '../../components/ManageCandidate/Selected';
import UnderReview from '../../components/ManageCandidate/UnderReview';
import Rejected from '../../components/ManageCandidate/Rejected';
import Enrolled from '../../components/ManageCandidate/Enrolled';
import CreateEvent from '../../components/ManageEvent/CreateEvent';
import EventList from '../../components/ManageEvent/EventList';
import Done from '../../components/ManagePayment/Done';
import UnderProcess from '../../components/ManagePayment/UnderProcess';
import PaymentView from '../../components/ManagePayment/PaymentView';
import ViewCandidates from '../../components/ManageCandidate/ViewCandidates';

const MobiliserHead = () => {
  const [manageEvent, setManageEvent] = useState(false)
  const [manageCandidate, setManageCandidate] = useState(false)
  const [selectedPath, setSelectedPath] = useState('dashboard')
  const [manageMobiliser, setManageMobiliser] = useState(false)
  const [managePayment, setManagePayment] = useState(false)

  const handleLeftNavPaths= (path) => {
    setSelectedPath(path)
  }

  const handleSelectedPathChange = (path) => {
    setSelectedPath(path);
  };
  
  const handleTabChange = () => {
    if(selectedPath === 'dashboard'){
      return <HeadDashboard/>
    }
    else if(selectedPath === 'addMobiliser'){
      return <AddMobiliser onFormSubmitSuccess={() => handleSelectedPathChange('viewMobiliser')}/>}
    else if(selectedPath ==='viewMobiliser')
       return <ViewMobiliser/>
    else if(selectedPath ==='viewCandidates')
      return <ViewCandidates/>
    else if(selectedPath ==='mobSwWise')
      return <MobSwWise/>
    else if(selectedPath ==='selected')
      return <Selected/>
    else if(selectedPath ==='underReview')
       return <UnderReview/>
    else if(selectedPath ==='rejected')
      return <Rejected/>
    else if(selectedPath ==='enrolled')
      return <Enrolled/>
    else if(selectedPath ==='createEvent')
      return <CreateEvent onFormSubmitSuccess={() => handleSelectedPathChange('eventList')}/>
    else if(selectedPath ==='eventList')
      return <EventList/>
    else if (selectedPath === 'paymentView'){
      return <PaymentView/>
      }
    else if(selectedPath ==='done')
      return <Done/>
    else if(selectedPath ==='underProcess')
      return <UnderProcess/>
    ///add your condition here...
    
  }

  return (
    <div className="s-app-container">
      <div className="s-content-wrapper">
        <div className="s-sidebar">
          <ul className='s-sidebar-list-container'>
            <li className={selectedPath ==='dashboard' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('dashboard')}>
                <GridViewIcon />
                <p>Dashboard</p>
            </li>
            <li onClick={() => setManageMobiliser(!manageMobiliser)}>
                <ManageHistoryIcon />
                <p>Manage Mobiliser</p>
                {manageMobiliser ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
            </li>
            {manageMobiliser &&
              <>
              <li className={selectedPath ==='addMobiliser' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('addMobiliser')}>
                <Diversity3Icon/>
                <p>Add Mobiliser</p>
              
            </li> <li className={selectedPath ==='viewMobiliser' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('viewMobiliser')}>
                <TocIcon/>
                <p>View Mobiliser</p>
              
            </li>
            </>
            }
            <li onClick={() => setManageCandidate(!manageCandidate)}>
              <ReceiptLongIcon/>
              <p>Manage Candidate</p>
              {manageCandidate ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
          </li>
          {manageCandidate &&
              <>
              <li className={selectedPath ==='viewCandidates' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('viewCandidates')}>
                <FormatListNumberedIcon/>
                <p>View Candidates</p>
            </li> 
              <li className={selectedPath ==='mobSwWise' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('mobSwWise')}>
              <Diversity2Icon/>
                <p>Mobiliser/SW Wise/Self</p>
            </li>
            <li className={selectedPath ==='selected' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('selected')}>
                <CheckCircleIcon/>
                <p>Selected</p>
            </li>
            <li className={selectedPath ==='underReview' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('underReview')}>
                <HourglassFullIcon/>
                <p>Under Review</p>
            </li>
            <li className={selectedPath ==='rejected' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('rejected')}>
                <CancelIcon/>
                <p>Rejected</p>
            </li>
            <li className={selectedPath ==='enrolled' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('enrolled')}>
                <SchoolIcon/>
                <p>Enrolled</p>
            </li>
            </>
            }
            <li onClick={() => setManageEvent(!manageEvent)}>
                <ManageAccountsIcon/>
                <p>Manage Event</p>
                {manageEvent ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
              
            </li>
            {manageEvent &&
              <>
              <li className={selectedPath ==='createEvent' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('createEvent')}>
                <NoteAddIcon/>
                <p>Create Event</p>
              
            </li>
             <li className={selectedPath ==='eventList' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('eventList')}>
                <ListIcon/>
                <p>Event List</p>
              
            </li>
            </>
            }
            <li onClick={() => setManagePayment(!managePayment)}>
                <PaymentIcon/>
                <p>Manage Payment</p>
                {managePayment ? <KeyboardArrowDownIcon fontSize='large'/>: <ArrowForwardIosIcon sx={{fontSize:'20px'}} />}
              
            </li>
            {managePayment &&
              <>
              <li className={selectedPath ==='paymentView' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('paymentView')}>
              <FormatListBulletedIcon/>
                <p>Payment List</p>
            </li>
            <li className={selectedPath ==='done' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('done')}>
              <CheckCircleIcon/>
                <p>Done</p>
            </li>
            <li className={selectedPath ==='underProcess' ? 'selectedTab': ''} onClick={() => handleLeftNavPaths('underProcess')}>
              <HourglassFullIcon/>
                <p>Under Process</p>
            </li>
            </>
            }
          </ul>
        </div>
        {handleTabChange()}
      </div>
    </div>
  );
};

export default MobiliserHead;