export const genderOptions = [
    {label:'Female'},
    {label: 'Male'}
]  
export const statusOptions = [
    {label:'selected'},
    {label: 'rejected'}
] 
export const candidateStatusOptions=[
    {label:'registered'},
    {label: 'moved'},
    {label:'underReview'},
    {label: 'underProcess'},
    {label:'selected'},
    {label: 'rejected'},
    {label: 'enrolled'}
]
export const paymentStatus = [
    {label: 'pending'},
    {label: 'partial'},
    {label: 'paid'}
]

export const postOptions = [
    {label: 'DSM'},
    {label: 'PSM'},
    {label: 'BSM'},
    {label: 'mobiliser'},
    {label: 'support'},
]

export const authorityOptions = [
    {label: 'AC'},
    {label: 'BDO'},
    {label: 'CDPO'},
    {label: 'LS/ASO'},
    {label: 'BTT'}
]

export const stateOptions = [
    { label: 'Andhra Pradesh' },
    { label: 'Arunachal Pradesh' },
    { label: 'Assam' },
    { label: 'Bihar' },
    { label: 'Chhattisgarh' },
    { label: 'Goa' },
    { label: 'Gujarat' },
    { label: 'Haryana' },
    { label: 'Himachal Pradesh' },
    { label: 'Jharkhand' },
    { label: 'Karnataka' },
    { label: 'Kerala' },
    { label: 'Madhya Pradesh' },
    { label: 'Maharashtra' },
    { label: 'Manipur' },
    { label: 'Meghalaya' },
    { label: 'Mizoram' },
    { label: 'Nagaland' },
    { label: 'Odisha' },
    { label: 'Punjab' },
    { label: 'Rajasthan' },
    { label: 'Sikkim' },
    { label: 'Tamil Nadu' },
    { label: 'Telangana' },
    { label: 'Tripura' },
    { label: 'Uttar Pradesh' },
    { label: 'Uttarakhand' },
    { label: 'West Bengal' },
    { label: 'Andaman and Nicobar Islands' },
    { label: 'Chandigarh' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { label: 'Delhi' },
    { label: 'Lakshadweep' },
    { label: 'Puducherry' }
];

export const jobroleOptions = [
    {label: 'fitter fabrication'},
    {label: 'automotive welding machine operator (manual and robotics)'},
    {label: 'assistant electrician'},
    {label: 'automotive electrician'},
    {label: 'soler pv installer (suryamitra)'},
    {label: 'smartphone assembly technician'},
    {label: 'telecom customer care executive - call center/relationship center'},
    {label: 'fashion designer'},
    {label: 'domestic data entry operator'},
    {label: 'sewing machine operator'},
    {label: 'self employed tailor'},
    {label: 'general duty assistant'},
    {label: 'plumber general'},
    {label: 'retails sales executive'},
    {label: 'user experience designer'},
    {label: 'marketing operations analysis'},
    {label: 'data associate'},
    {label: 'media developer'},
    {label: 'domestic it helpdesk attendant'},
    {label: 'technical support engineer'}
];
export const trainingCenterOptions = [
    {label: 'Tatisilwai'},
    {label: 'Lalganj'},
    {label: 'Thakurgaon'},
    {label: 'Jamshedpur'},
    {label: 'Sahebganj'},
    {label: 'Dumka'},
    {label: 'Palamu'},
    {label: 'Gumla'},
    {label: 'Garu'},
    {label: 'Shikaripada'}
];

