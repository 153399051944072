import React, { useEffect } from 'react';
import { Box, TextField } from '@mui/material';

const ViewCandidateModal = ({ candidate, onClose }) => {
  useEffect(() => {
    console.log('Candidate data:', candidate);
  }, [candidate]);

  if (!candidate) return null;

  return (
    <Box className="v-main-container">
      <div className="v-inputs-container" style={{ gap: '20px', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <TextField label="Name" value={candidate.name} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Source" value={candidate.source} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Camp Name" value={candidate.campName} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Father's Name" value={candidate.fatherName} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Mother's Name" value={candidate.motherName} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Education Level" value={candidate.educationLevel} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Gender" value={candidate.gender} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="DOB" value={candidate.dob} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Age" value={candidate.age} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Mobile Number" value={candidate.mobileNo} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Guardian Mobile Number" value={candidate.guardianMobileNo} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Aadhar No." value={candidate.aadharNo} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Address Type" value={candidate.addressType} InputProps={{ readOnly: true }} fullWidth />
        {candidate.addressType === 'Urban' ? (
          <>
            <TextField label="Address" value={candidate.address} InputProps={{ readOnly: true }} fullWidth />
            <TextField label="Ward Number" value={candidate.wardNumber} InputProps={{ readOnly: true }} fullWidth />
          </>
        ) : (
          <>
            <TextField label="Village" value={candidate.village} InputProps={{ readOnly: true }} fullWidth />
            <TextField label="Panchayat" value={candidate.panchayat} InputProps={{ readOnly: true }} fullWidth />
            <TextField label="Post Office" value={candidate.postOffice} InputProps={{ readOnly: true }} fullWidth />
          </>
        )}
        <TextField label="District" value={candidate.district} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="State" value={candidate.state} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Block" value={candidate.block} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Landmark" value={candidate.landmark} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Preferred Job Role" value={candidate.preferredJobRole} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Photo" value={candidate.photo} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Aadhar Image" value={candidate.aadharImage} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Status" value={candidate.status} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Training Centre" value={candidate.trainingCentre} InputProps={{ readOnly: true }} fullWidth />
        <TextField label="Any Query?" value={candidate.anyQuery} InputProps={{ readOnly: true }} fullWidth />
        {candidate.stakeHolders && candidate.stakeHolders.length > 0 && (
          <>
            <h3>Stakeholders</h3>
            {candidate.stakeHolders.map((stakeholder, index) => (
              <div key={index} className="v-inputs-container" style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <TextField
                  label={`Stakeholder ${index + 1} Name`}
                  value={stakeholder.name}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
                <TextField
                  label={`Stakeholder ${index + 1} Post`}
                  value={stakeholder.post}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
                <TextField
                  label={`Stakeholder ${index + 1} ID`}
                  value={stakeholder.sid}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </div>
            ))}
          </>
        )}
        {candidate.createdBy && (
          <>
            <h3>Registered By</h3>
            <TextField label="Name" value={`${candidate.createdBy.fname} ${candidate.createdBy.lname}`} InputProps={{ readOnly: true }} fullWidth />
            <TextField label="User Type" value={candidate.createdBy.userType} InputProps={{ readOnly: true }} fullWidth />
            {candidate.createdBy.userType === 'leader' && (
              <TextField label="Post" value={candidate.createdBy.post} InputProps={{ readOnly: true }} fullWidth />
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default ViewCandidateModal;
